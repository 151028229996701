<template>
   <div class="legalPolicies">
      <div class="container">
         <h2 class="title-secondary">{{ getMyLanguage("legalPolicies", "legalPolicies.title") }}</h2>

         <div class="row">
            <div class="col-6 col-lg-3">
               <a :href="`/${flagSelected}/terms-and-conditions`" class="btn-legalPolicies">
                  <img src="~@/assets/images/icons/legalPolicies-terms-conditions.svg">
                  <span>{{getMyLanguage("terms-policies","terms-conditions.title")}}</span>
               </a>
            </div>
            
            <div class="col-6 col-lg-3">
               <a :href="`/${flagSelected}/privacy`" class="btn-legalPolicies">
                  <img src="~@/assets/images/icons/legalPolicies-privacy.svg">
                  <span>{{getMyLanguage("terms-policies","privacy.title")}}</span>
               </a>
            </div>

            <div class="col-6 col-lg-3">
               <a :href="`${VUE_APP_INTERNATIONAL_URL}${flagSelected}/company-registration`" target="_blank" class="btn-legalPolicies">
                  <img src="https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/d5bf4f1a-0f33-4303-016f-a372248d5700/icons">
                  <span>{{getMyLanguage("company-registration","registration.title")}}</span>
               </a>
            </div>
            
            
            <!-- LIST POLICIES -->
            <div class="col-6 col-lg-3" v-for="(listPolicies,index) in legalPolicies" :key="index">
               <div class="btn-legalPolicies" :class="listPolicies.slugModal" @click="openModal(listPolicies.title, listPolicies.content)">
                  <img :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${listPolicies.icon}/icons`">
                  <span>{{ listPolicies.title }}</span>
               </div>
            </div>
            
         </div>
      </div>

      <!-- MODAL -->
      <div class="modal" v-if="modal.status === true">
         <div class="modal-cont">
            <div class="modal-close" @click="modal.status = false"></div>
            <h2 class="title-secondary">{{ modal.titleModal }}</h2>
            <div v-html="modal.contentModal" class="modal-scroll"></div>
         </div>
      </div>

   </div>
</template>


<script>
   import getMyContentLanguage from "@/services/contentLanguage.js";

   import legalAndPolicies_pt from '@/legalPolicies/pt.json';
   import legalAndPolicies_en from '@/legalPolicies/en.json';
   import legalAndPolicies_es from '@/legalPolicies/es.json';
   import legalAndPolicies_ar from '@/legalPolicies/ar.json';
   import legalAndPolicies_ru from '@/legalPolicies/ru.json';
   import legalAndPolicies_br from '@/legalPolicies/br.json';
   import legalAndPolicies_de from '@/legalPolicies/de.json';
   import legalAndPolicies_fr from '@/legalPolicies/fr.json';
   import legalAndPolicies_it from '@/legalPolicies/it.json';

   export default {
      data () {
         return {
            VUE_APP_INTERNATIONAL_URL: process.env.VUE_APP_INTERNATIONAL_URL,
            flagSelected: "en",
            legalPolicies: '',

            modal: {
               flagSelected: "en",
               status: false,
               titleModal: '',
               contentModal: ''
            }
         }
      },

      async mounted(){
         setTimeout(() => {
            this.flagSelected = localStorage.getItem("codeLanguage")
         }, 500);
         
         this.languageLegalPolicies()
      },

      methods: {
         getMyLanguage(group, code){
            return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code)
         },

         languageLegalPolicies(){
            this.flagSelected = this.$store.state.SelectLanguage.code;
            
            const languageCode = this.$store.state.SelectLanguage.code;

            if ( languageCode === 'pt' ){
               this.legalPolicies = legalAndPolicies_pt
            } else if ( languageCode === 'es' ){
               this.legalPolicies = legalAndPolicies_es
            } else if ( languageCode === 'ar' ){
               this.legalPolicies = legalAndPolicies_ar
            } else if ( languageCode === 'ru' ){
               this.legalPolicies = legalAndPolicies_ru
            } else if ( languageCode === 'br' ){
               this.legalPolicies = legalAndPolicies_br
            } else if ( languageCode === 'de' ){
               this.legalPolicies = legalAndPolicies_de
            } else if ( languageCode === 'fr' ){
               this.legalPolicies = legalAndPolicies_fr
            } else if ( languageCode === 'it' ){
               this.legalPolicies = legalAndPolicies_it
            } else {
               this.legalPolicies = legalAndPolicies_en
            }
         },

         openModal(title, content){
            let titleModal = title
            let contentModal = content

            this.modal.status = true
            this.modal.titleModal = titleModal
            this.modal.contentModal = contentModal
         }
      },

      watch: {
         '$store.state.SelectLanguage.code': {
            immediate: true,
            handler() {
               this.languageLegalPolicies();
            }
         }
      }
   }
</script>

<style src="@/assets/scss/_legalPolicies.scss" lang="scss" scoped />

<style lang="scss">
   .btn-legalPolicies.complaintBook img{width: 26px; height: 0; padding-top: 24px; overflow: hidden; background: url('~@/assets/images/icons/legalPolicies-book.svg') no-repeat; background-size: auto 100%;}
   @media (max-width: 576px){
      .btn-legalPolicies.complaintBook img{width: 20px; padding-top: 16px;}
   }

   .modal-scroll{
      a{display: inline-block; color: #4aae57;}
      p > span{font-weight: 600; text-decoration: underline;}
      ol, ul{padding-left: 30px; margin: 20px 0;}
      ul > li{list-style: disc}
      img{margin-top: 30px;}
   }
</style>