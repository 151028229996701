<template>
    <div class="main">
        <div class="home">
            <div id="dne"></div>
            <Header :dataSource="PageEntity" />

            <div id="clientSpace">
                <section class="client-space">
                    <div class="anc" id="client-space"></div>
                    <div class="container">
                        <ClientSpaceResume />
                    </div>
                </section>

                <a id="anchor-content"></a>

                <!-- UPDATED ACCOUNT -->
                <section class="account">
                    <div class="anc" id="edt-account"></div>
                    <div class="container">
                        <div class="cont-updated-account">
                            <div class="container">
                                <div class="box">
                                    <h2 class="title-secondary">{{ getMyLanguage("client-space", "client-space.account-success.title") }}</h2>

                                    <img src="~@/assets/images/icons/register-success.svg" class="icon-success">
                                    <p>{{ getMyLanguage("client-space", "client-space.account-success.msg") }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Footer />
        </div>
    </div>
</template>

<script>
    import Header from '@/components/Header.vue';
    import ClientSpaceResume from '@/components/ClientSpaceResume.vue';
    import Footer from '@/components/Footer.vue';

    import getMyContentLanguage from "@/services/contentLanguage.js";
  
    export default {
        components: {
            Header,
            ClientSpaceResume,
            Footer
        },

        data (){
            return{
                PageEntity: "ecosystem",
            }
        },

        methods: {
            getMyLanguage(group, code){
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code)
            },

            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code

                document.title = this.getMyLanguage("seo","title.client-space.account");
                document.getElementsByTagName('meta')["description"].content = ''
            },
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        },

        created () {
            document.title = this.getMyLanguage("seo","title.client-space.account");
            document.getElementsByTagName('meta')["description"].content = ''
            document.head.querySelector('meta[name=robots]').content = 'noindex, nofollow'
        }
    }
</script>

<style src="./custom-clientSpace.scss" lang="scss" scoped />
<style src="./custom-clientSpaceAccount.scss" lang="scss" scoped />