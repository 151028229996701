import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home/Home.vue'
import ConfirmEmail from '../views/Account/ConfirmEmail.vue'
import Activate from '../views/Account/Activate.vue'
import Confirm from '../views/Account/Confirm.vue'
import Invite from '../views/Account/Invite.vue'
import ConfirmEmailInvite from '../views/Account/ConfirmEmailInvite.vue'
import Register from '../views/Account/Register.vue'
import RegisterSuccess from '../views/Account/RegisterSuccess.vue'
import RegisterModal from '../views/Account/RegisterModal.vue'
import RegisterSuccessModal from '../views/Account/RegisterSuccessModal.vue'
import RegisterLead from '../views/Account/RegisterLead.vue'
import RegisterLeadSuccess from '../views/Account/RegisterLeadSuccess.vue'
import RegisterPrivateSale from '../views/Account/RegisterPrivateSale.vue'
import ProfileCorporate from '../views/Account/ProfileCorporate.vue'
import Login from '../views/Account/Login.vue'
import RememberPass from '../views/Account/RememberPass.vue'
import Consent from '../views/Account/Consent.vue'
import ClientSpace from '../views/ClientSpace/ClientSpace.vue'
import ClientSpaceAccount from '../views/ClientSpace/ClientSpaceAccount.vue'
import ClientSpaceAccountSuccess from '../views/ClientSpace/ClientSpaceAccountSuccess.vue'
import Support from '../views/Support/Support.vue'

import DneMarket from '../views/DneMarket/DneMarket.vue'
import DnePay from '../views/DnePay/DnePay.vue'
import DneExchange from '../views/DneExchange/DneExchange.vue'
import DneBank from '../views/DneBank/DneBank.vue'

import TermsConditions from '../views/TermsConditions/TermsConditions.vue'
import Privacy from '../views/Privacy/Privacy.vue'
import NotFound from '../views/NotFound/NotFound.vue'

const routes = [
  { path: '/', name: 'redirect', redirect: '/en', component: Home },
  { path: '/:lang/', name: 'Home', component: Home },
  { path: '/:lang/private-sale', name: 'RegisterPrivateSale', component: RegisterPrivateSale },
  { path: '/:lang/account/activate/:registerId', name: 'Activate', component: Activate },
  { path: '/:lang/account/confirm/:registerId', name: 'Confirm', component: Confirm },
  { path: '/:lang/account/invite/:registerId', name: 'Invite', component: Invite },
  { path: '/:lang/account/confirm-email', name: 'ConfirmEmail', component: ConfirmEmail },
  { path: '/:lang/account/confirm-email-invite/:registerId', name: 'ConfirmEmailInvite', component: ConfirmEmailInvite },
  { path: '/:lang/account/register', name: 'Register', component: Register },
  { path: '/:lang/account/register-success', name: 'RegisterSuccess', component: RegisterSuccess },
  { path: '/:lang/account/register-modal', name: 'RegisterModal', component: RegisterModal },
  { path: '/:lang/account/register-success-modal', name: 'RegisterSuccessModal', component: RegisterSuccessModal },
  { path: '/:lang/account/lead', name: 'RegisterLead', component: RegisterLead },
  { path: '/:lang/account/lead-success', name: 'RegisterLeadSuccess', component: RegisterLeadSuccess },
  { path: '/:lang/account/profile-corporate', name: 'ProfileCorporate', component: ProfileCorporate },
  { path: '/:lang/account/login', name: 'Login', component: Login },
  { path: '/:lang/account/remember-pass/:registerId', name: 'RememberPass', component: RememberPass },
  { path: '/:lang/account/consent/ask', name: 'Consent', component: Consent },
  { path: '/:lang/client-space/', name: 'ClientSpace', component: ClientSpace },
  { path: '/:lang/client-space-account/', name: 'ClientSpaceAccount', component: ClientSpaceAccount },
  { path: '/:lang/client-space-account-success/', name: 'ClientSpaceAccountSuccess', component: ClientSpaceAccountSuccess },
  { path: '/:lang/support/', name: 'Support', component: Support },
  
  { path: '/:lang/market', name: 'DneMarket', component: DneMarket },
  { path: '/:lang/pay', name: 'DnePay', component: DnePay },
  { path: '/:lang/exchange', name: 'DneExchange', component: DneExchange },
  { path: '/:lang/bank', name: 'DneBank', component: DneBank },
  
  { path: '/:lang/terms-and-conditions', name: 'TermsConditions', component: TermsConditions },
  { path: '/:lang/privacy', name: 'Privacy', component: Privacy },
  
  { path: '/:lang/:catchAll(.*)', component: NotFound },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


export default router
