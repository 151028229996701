<template>
    <div class="main">
        <div class="home">
            <div id="dne"></div>
            <Header :dataSource="PageEntity" />

            <div id="confirmEmail">
                <section class="confirm-email">
                    <div class="skew-right no-skew-mobile">

                        <div class="load load-white" v-if="!showContent"></div>

                        <div class="container" v-if="showContent">
                            <div class="row">
                                <div class="col-12 col-lg-6">
                                    <h2 class="title-secondary">{{ getMyLanguage("confirm", "confirm.title") }}</h2>

                                    <div class="box-confirm-email">
                                        <div class="msg" v-html='getMyLanguage("confirm", "confirm.msg")'></div>                                    
                                        <div class="cordially">{{ getMyLanguage("confirm-email", "confirm-email.cordially") }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
        <Footer />
        <CookieConsent />
    </div>
</template>

<script>
    import Header from '@/components/Header.vue';
    import Footer from '@/components/Footer.vue';
    import CookieConsent from '@/components/CookieConsent.vue';
    import getMyContentLanguage from "@/services/contentLanguage.js";

    import apiPlatform from '@/config/apiPlatform.js'
  
    export default {
        components: {
            Header,
            Footer,
            CookieConsent
        },
        
        data(){
            return{
                PageEntity: "ecosystem",
                dataConfirm: [],
                showContent: false,
                acceptyTerms: false
            }
        },   

        async mounted(){
            const resumeConfirm = await apiPlatform.get(`/api/v1/platform/account/confirm/${this.$route.params.registerId}`)
            this.dataConfirm = resumeConfirm.data

            this.showContent = true
        },

        methods: {
            getMyLanguage(group, code){
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code)
            }
        },

        created () {
            document.title = this.getMyLanguage("seo","title.account.confirm");
            document.getElementsByTagName('meta')["description"].content = ''
            document.head.querySelector('meta[name=robots]').content = 'noindex, nofollow'
        },
    }
</script>

<style src="./custom-confirm-email.scss" lang="scss" scoped />