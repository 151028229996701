<template>
    <div class="main">
        <div class="home">
            <div id="dne"></div>
            <Header :dataSource="PageEntity" />

            <div id="confirmEmail">
                <section class="confirm-email">
                    <div class="skew-right no-skew-mobile">
                        <div class="container">
                            <div class="row">
                                <div class="col-12 col-lg-6">
                                    <div class="load-white load-200" v-if="!showContent"></div>

                                    <div class="box-confirm-email confirm-email-success" v-if="showContent">
                                        <h2 class="title-secondary">{{ getMyLanguage("confirm-email", "confirm-email.welcome") }}</h2>

                                        <div class="icon-confirm-email">
                                            <img src="~@/assets/images/icons/register-success.svg">
                                        </div>

                                        <div class="msg msg-success"><p>{{ getMyLanguage("confirm-email", "register-success") }}</p></div>
                                        <div class="msg msg-attention" v-if="origin">
                                            <a :href="`/${flagSelected}/account/login`" class="btn-primary" v-html='getMyLanguage("confirm-email","cta-create-account")'></a>
                                            <a :href="`${VUE_APP_MARKET_URL}${flagSelected}/`" class="btn-primary" v-html='getMyLanguage("confirm-email","cta-dne-market")'></a>
                                        </div>

                                        <div class="msg msg-attention" v-else>
                                            <p v-html='getMyLanguage("confirm-email", "confirm-email-spam")'></p>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
        <Footer />
        <CookieConsent />
    </div>
</template>

<script>
    import Header from '@/components/Header.vue';
    import Footer from '@/components/Footer.vue';
    import CookieConsent from '@/components/CookieConsent.vue';

    import getMyContentLanguage from "@/services/contentLanguage.js";
  
    export default {
        components: {
            Header,
            Footer,
            CookieConsent
        },

        data(){
            return{
                flagSelected: "en",
                VUE_APP_MARKET_URL: process.env.VUE_APP_MARKET_URL,
                showContent: false,
                origin: "",
                PageEntity: "ecosystem",
            }
        },
        
        async mounted(){
            const urlParams = new URLSearchParams(window.location.search);
            this.origin = urlParams.get('activated')

            console.log(this.origin)

            setTimeout(() => {
                this.showContent = true
            }, 1500);
        },

        methods: {
            getMyLanguage(group, code){
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code)
            },

            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code

                document.title = this.getMyLanguage("seo","title.account.confirmEmail");
                document.getElementsByTagName('meta')["description"].content = ''
            },
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        },

        created () {
            document.title = this.getMyLanguage("seo","title.account.confirmEmail");
            document.getElementsByTagName('meta')["description"].content = ''
            document.head.querySelector('meta[name=robots]').content = 'noindex, nofollow'
        }
    }
</script>

<style src="./custom-confirm-email.scss" lang="scss" scoped />
<style scoped>
    .icon-confirm-email img{width: 140px !important; height: auto;}
    .btn-primary{
        height: 80px;
        line-height: 22px;
    }
</style>