<template>
    <section class="team">
        <div class="anc" id="team"></div>


        <div class="container">
            <h2 class="title-secondary" data-aos="zoom-out" data-aos-offset="-200">{{getMyLanguage("team","team.title")}}</h2>

            <div class="row row-member-master">
                <div class="col-12 col-lg-4" v-for="(listTeamMaster,index) in teamFull.teamMaster" :key="index" :class="listTeamMaster.class">
                    <div class="group-team">
                        <div class="member-master" :class="listTeamMaster.memberMaster.class">

                            <div class="photo" data-aos="zoom-in" data-aos-offset="-200">
                                <div class="circle-photo" data-aos="zoom-out" data-aos-offset="-200" data-aos-delay="150">
                                    <img :src="`/team/${listTeamMaster.memberMaster.photo}`" :alt="listTeamMaster.memberMaster.name">
                                </div>
                            </div>

                            <div class="team-box" data-aos="fade-up" data-aos-offset="-200">
                                <h3 class="perksDesk">{{listTeamMaster.memberMaster.position}}</h3>
                                <h6>{{listTeamMaster.memberMaster.name}}</h6>
                                <p v-html="listTeamMaster.memberMaster.perks"></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row list-team">
                <div class="col-12 col-lg-6 member-team" v-for="(teamList,n) in teamFull.teamDNE" :key="n" :class='{noPhoto: teamList.photo === "no-photo"}'>
                    <div class="photo" v-if='teamList.photo != "no-photo"' data-aos="zoom-in" data-aos-offset="-200">
                        <div class="circle-photo">
                            <img :src="`/team/${teamList.photo}`" :alt="teamList.name">
                        </div>
                    </div>

                    <div class="team-box" data-aos="fade-up" data-aos-offset="-200">
                        <h3 class="perksDesk" v-if='teamList.position != ""'>{{teamList.position}}</h3>
                        <h6>{{teamList.name}}</h6>
                        <p v-html="teamList.perks"></p>
                    </div>
                </div>
            </div>

            <div class="row team-world-map">
                <div class="col-12">
                    <img :src="`/home/team-world-map-${flagSelected}.png`" alt="Team World Map" data-aos="fade-up" data-aos-offset="-200">
                </div>
            </div>
        </div>
    </section>
</template>


<script>
    import getMyContentLanguage from "@/services/contentLanguage.js";

    import team_pt from '@/ico/team-pt.json';
    import team_en from '@/ico/team-en.json';
    import team_es from '@/ico/team-es.json';
    import team_ar from '@/ico/team-ar.json';
    import team_ru from '@/ico/team-ru.json';
    import team_br from '@/ico/team-br.json';
    import team_de from '@/ico/team-de.json';
    import team_fr from '@/ico/team-fr.json';
    import team_it from '@/ico/team-it.json';

    export default {
        data () {
            return {
                flagSelected: "en",
                showTeam: false,
                teamFull: ""
            }
        },

        async mounted(){
            setTimeout(() => {
                this.flagSelected = localStorage.getItem("codeLanguage")
            }, 500);
        },
        
        methods: {
            getMyLanguage(group, code){
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code)
            },

            languageTeam(){
                this.flagSelected = this.$store.state.SelectLanguage.code
                const languageCode = this.$store.state.SelectLanguage.code;

                if ( languageCode === 'pt' ){
                    this.teamFull = team_pt
                } else if ( languageCode === 'es' ){
                    this.teamFull = team_es
                } else if ( languageCode === 'ar' ){
                    this.teamFull = team_ar
                } else if ( languageCode === 'ru' ){
                    this.teamFull = team_ru
                } else if ( languageCode === 'br' ){
                    this.teamFull = team_br
                } else if ( languageCode === 'de' ){
                    this.teamFull = team_de
                } else if ( languageCode === 'fr' ){
                    this.teamFull = team_fr
                } else if ( languageCode === 'it' ){
                    this.teamFull = team_it
                } else {
                    this.teamFull = team_en
                }
            }
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageTeam();
                }
            }
        }
    }
</script>


<style src="@/assets/scss/_team.scss" lang="scss" scoped />