<template>
  <div class="main">
    <div class="entity-construction">
      <div id="dne"></div>
      <Header :dataSource="PageEntity" />

      <section class="content-construction">
        <div class="construction">
          <h1 class="title-primary">{{getMyLanguage("dne-platform","home.products.bank.title")}}</h1>
          <p>{{ getMyLanguage("construction", "construction.title") }}</p>
        </div>
      </section>
   </div>
   <Footer />
   <CookieConsent />
  </div>
</template>


<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import CookieConsent from "@/components/CookieConsent.vue";
import getMyContentLanguage from "@/services/contentLanguage.js";

export default {
   components: {
      Header,
      Footer,
      CookieConsent
   },

   data (){
      return {
         PageEntity: "bank",
      }
   },

   methods: {
      getMyLanguage(group, code) {
         return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code);
      }
   },

   created() {
      // document.title = this.getMyLanguage("seo", "title.dneBank");
      document.title = "DNE Bank | DNE Token";
      document.getElementsByTagName('meta')["description"].content =
      this.getMyLanguage("seo", "title.description.dneBank");
   },
}
</script>

<style src="./custom-bank.scss" lang="scss" scoped />

<style>
   html,body,#app,.main{height: 100%;}
</style>