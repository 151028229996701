<template>
    <div class="main register-lead">
        <div class="home">
            <div id="dne"></div>
            <Header :dataSource="PageEntity" />
            
            <div id="confirmEmail">
                <section class="confirm-email">

                    <!-- LANDING PAGE -->
                    <h1 class="title-secondary">{{ getMyLanguage("lp-private-sale","title") }}</h1>
                    <div class="load load-white load-200" v-if="!showContent"></div>
                    
                    <div class="container lp-private-sale" v-if="showContent">
                        <div class="row">
                            <div class="col-12 col-lg-7">
                                <div class="box-text">
                                    <h2 v-html='getMyLanguage("lp-private-sale","box-03.title")'></h2>
                                    <p v-html='getMyLanguage("lp-private-sale","box-03.text-02")'></p>
                                    <p v-html='getMyLanguage("lp-private-sale","box-03.text-03")'></p>
                                    
                                    <div class="download-presentation">
                                        <p v-html='getMyLanguage("lp-private-sale","box-03.text-04")'></p>

                                        <div class="bts">
                                            <a :href='"https://cdn.dneecosystem.com/docs/marketing/DNE-Ecosystem-" + flagSelected + ".pdf?v=" + Math.floor(Math.random() * 99999)' target="_blank" class="download">
                                                <span>
                                                    {{ getMyLanguage("lp-private-sale","btn-presentation") }}
                                                    <small>PDF Download</small>
                                                </span>
                                            </a>
                                            
                                            <a :href='"https://cdn.dneecosystem.com/docs/WhitePaper-" + flagSelected + ".pdf?v=" + Math.floor(Math.random() * 99999)' target="_blank" class="download whitepaper">
                                                <span>
                                                    Whitepaper
                                                    <small>PDF Download</small>
                                                </span>
                                            </a>
                                        </div>
                                    </div>

                                    <div class="btn-anchor">
                                        <a href="#interested" class="btn-primary">{{ getMyLanguage("register","register.title.interested") }}</a>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 col-lg-5">
                                <img src="~@/assets/images/lp/dne-token.jpg" alt="DNE Token">
                            </div>
                        </div>

                        <div class="row row-reverse">
                            <div class="col-12 col-lg-5">
                                <img src="~@/assets/images/lp/blockchain.jpg" alt="Blockchain">
                            </div>

                            <div class="col-12 col-lg-7">
                                <div class="box-text">
                                    <h2 v-html='getMyLanguage("lp-private-sale","box-01.title")'></h2>
                                    <p v-html='getMyLanguage("lp-private-sale","box-01.text-01")'></p>
                                    <p v-html='getMyLanguage("lp-private-sale","box-01.text-02")'></p>
                                    <p v-html='getMyLanguage("lp-private-sale","box-01.text-03")'></p>

                                    <div class="btn-anchor">
                                        <a href="#interested" class="btn-primary">{{ getMyLanguage("register","register.title.interested") }}</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12 col-lg-7">
                                <div class="box-text">
                                    <h2 v-html='getMyLanguage("lp-private-sale","box-02.title")'></h2>
                                    <p v-html='getMyLanguage("lp-private-sale","box-02.text-01")'></p>
                                    <p v-html='getMyLanguage("lp-private-sale","box-02.text-02")'></p>
                                    <p v-html='getMyLanguage("lp-private-sale","box-02.text-03")'></p>

                                    <div class="btn-anchor">
                                        <a href="#interested" class="btn-primary">{{ getMyLanguage("register","register.title.interested") }}</a>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="col-12 col-lg-5">
                                <img src="~@/assets/images/lp/project.jpg" alt="Project">
                            </div>
                        </div>
                    </div>
                    
                    
                                        
                    <!-- BOX REGISTRATION -->
                    <div class="container">
                        <div class="row" v-if='showContent && !errorsPost.newRegister'>
                            <div class="col-12 col-lg-6">
                                <h2 class="title-secondary">{{ getMyLanguage("register","register.title.interested") }}</h2>
                                
                                <div class="box-confirm-email">
                                    <div class="anchor" id="interested"></div>
                                    <p class="subtitle">{{ getMyLanguage("register","register.title.interested.msg") }}</p>
                                    <div class="box-registration lp-registration" :class="{dropListOpened: (dropListOpened.languageCompany || dropListOpened.countryCompany || dropListOpened.ddiCompany || dropListOpened.languagePerson || dropListOpened.countryPerson || dropListOpened.ddiPerson)}">
                                        <div class="formBox">
                                            
                                            <div class="invite-person">
                                                <div class="anc anc-company" id="!person"></div>

                                                <h3 v-if='flow.typeCode === "account_type_company"'>{{getMyLanguage("purchase-token","purchase-token.content.box-register.subtitle.partnerData")}}</h3>

                                                <!-- NAME -->
                                                <label class="formFullName">
                                                    <span class="ph">{{getMyLanguage("purchase-token", "purchase-token.content.box-register.placeholder.fullname")}}</span>
                                                    <input v-model="newRegister.person.fullName" type="text" class="form" :class="{error: errorForm.personInvite.fullName}" @keydown="errorForm.personInvite.fullName = false" maxlength="80" autocomplete="off" >
                                                </label>
                                                <p class="msg-validate-form msg-error anime" v-if='errorForm.personInvite.fullName'>{{getMyLanguage("errors","errors.nameError")}}</p>


                                                <!-- EMAIL -->
                                                <label class="formYourEmail">
                                                    <span class="ph">{{getMyLanguage("client-space","client-space.account.lbl.email")}}</span>
                                                    <input v-model="newRegister.person.email" type="email" id="email" class="form formYourEmail" :class='{error: (errorForm.personInvite.email)}' @blur='regexEmail()' maxlength="60" autocomplete="off">
                                                </label>
                                                <p class="msg-validate-form msg-error anime" v-if='validateEmail.person.regex === false || errorForm.personInvite.email'>{{getMyLanguage("errors","errors.emailError")}}</p>


                                                <!-- LANGUAGE -->
                                                <div class="autocomplete formLanguage" :class="{opened: dropListOpened.languagePerson}">
                                                    <div class="openAutocomplete" v-if="!listLanguagesStatus" @click="dropListLanguages(true)"></div>
                                                    <div class="closeAutocomplete closeDropListCountry" v-if="listLanguagesStatus" v-on:click="dropListLanguages(false)"></div>

                                                    <label>
                                                        <span class="ph">{{getMyLanguage("purchase-token", "purchase-token.content.box-register.placeholder.language")}}</span>
                                                        <img :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${selectedLanguageFlag}/flags`" class="flag-selected" v-if="selectedLanguageFlag != false">
                                                        <input type="text" id="languageID" class="form" :class="{selected: selectedLanguageFlag != false, error: errorForm.personInvite.languageId}" @focus="dropListLanguages(true)" v-on:keyup="filterListLanguage" @blur="dropListLanguages(false)" @keydown.esc="dropListLanguages(false)" :value="languagesList(selectedLanguage.code)" :title='getMyLanguage("purchase-token", "purchase-token.content.box-register.placeholder.language")' autocomplete="off">
                                                    </label>
                                                    <p class="msg-validate-form msg-error anime" v-if='errorForm.personInvite.languageId'>{{getMyLanguage("errors","errors.language")}}</p>

                                                    <ul id="drop-list" v-if="listLanguagesStatus">
                                                        <li v-for="(itemList,index) in langsTranslated" :key="index" v-on:click="(newRegister.person.languageId = itemList.id),(selectedLanguage.code = itemList.code),(selectedLanguageFlag = itemList.flag), (dropListLanguages(false)), errorForm.personInvite.languageId = false">
                                                            <img :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${itemList.flag}/flags`">
                                                            <a>{{ languagesList(itemList.code) }}</a>
                                                        </li>
                                                    </ul>
                                                </div>

                                                <!-- COUNTRY -->
                                                <div class="autocomplete formResidenceCountry" :class="{opened: dropListOpened.countryPerson}">
                                                    <div class="openAutocomplete" v-if="!listCountryStatus" @click="dropListCountry(true)"></div>
                                                    <div class="closeAutocomplete closeDropListCountry" v-if="listCountryStatus" v-on:click="dropListCountry(false)"></div>

                                                    <label>
                                                        <span class="ph">{{getMyLanguage("purchase-token", "purchase-token.content.box-register.placeholder.residence")}}</span>
                                                        <img :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${selectedCountryFlag}/flags`" class="flag-selected" v-if="selectedCountryFlag != false">
                                                        <input type="text" id="residenceCountry" class="form" :class="{selected: selectedCountryFlag != false, error: errorForm.personInvite.countryId}" @focus="dropListCountry(true)" @blur="dropListCountry(false)" @keydown.esc="dropListCountry(false)" v-on:keyup="filterListCountry" :value="countryTranslate(selectedCountry.IsoCode)" :title='getMyLanguage("purchase-token", "purchase-token.content.box-register.placeholder.residence")' autocomplete="off">
                                                    </label>
                                                    <p class="msg-validate-form msg-error anime" v-if='errorForm.personInvite.countryId'>{{getMyLanguage("errors","errors.residenceError")}}</p>

                                                    <ul id="drop-list" v-if="listCountryStatus">
                                                        <li v-for="(itemList,index) in countriesTranslated" :key="index" v-on:click="(newRegister.person.countryId = itemList.Id), (newRegister.person.phoneCountryId = itemList.Id), (this.changeMask()), (selectedCountryFlag = itemList.Flag), (selectedCountryDDI.countrySelected = true), (selectedCountryDDI.active = 'active'), (selectedCountryDDI.phoneMask = itemList.PhoneMask), (selectedCountryDDI.ddi = itemList.DDI), (selectedCountryDDI.flag = itemList.Flag), (selectedCountry.IsoCode = itemList.IsoCode), (dropListCountry(false)), errorForm.personInvite.countryId = false">
                                                            <img :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${itemList.Flag}/flags`">
                                                            <a>{{ countryTranslate(itemList.IsoCode)}}</a>
                                                        </li>
                                                    </ul>
                                                </div>

                                                <!-- PHONE -->
                                                <div class="formPhone" :class='{opened: dropListOpened.ddiPerson, active: selectedCountryDDI.active, error: (errorsPost.newRegister === "account_activate_phone_already_exists" || errorForm.personInvite.phoneNumber)}'>
                                                    <label>
                                                        <span class="ph">{{getMyLanguage("purchase-token", "purchase-token.content.box-register.placeholder.phone")}}</span>
                                                    </label>
                                                    
                                                    <div class="d-flex">
                                                        <div class="select-ddi" v-if="selectedCountryDDI.countrySelected && selectedCountryDDI.changeMask">
                                                            <div class="open-select-ddi" @click="dropListDDI(true)"></div>
                                                            <div class="close-select-ddi" v-if="selectedCountryDDI.openSelect" @click="dropListDDI(false)"></div>
                                                            <div class="selected-ddi">
                                                                <div class="flag">
                                                                    <img :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${selectedCountryDDI.flag}/flags`" v-if="selectedCountryDDI.flag != ''">
                                                                </div>
                                                                <span class="ddi">+{{selectedCountryDDI.ddi}}</span>
                                                            </div>
                                                            <ul class="list-ddi" v-if="selectedCountryDDI.openSelect">
                                                                <div class="scroll">
                                                                    <li v-for="(ddiList,index) in dataCountry" :key="index" @click='(newRegister.person.phoneCountryId = ddiList.Id), (selectedCountryDDI.phoneMask = ddiList.PhoneMask), (this.changeMask()), (selectedCountryDDI.ddi = ddiList.DDI), (selectedCountryDDI.flag = ddiList.Flag), (dropListDDI(false)), (newRegister.person.phoneNumber = "")'>
                                                                        <div class="flag">
                                                                            <img class="anime" :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${ddiList.Flag}/flags`">
                                                                        </div>
                                                                        <span class="name-country">{{ddiList.Name}}</span>
                                                                        <span class="ddi" >(+{{ddiList.DDI}})</span>
                                                                    </li>
                                                                </div>
                                                            </ul>
                                                        </div>

                                                        <label v-if="selectedCountryDDI.changeMask">
                                                            <input v-model="validatePhone.person.val" id="phoneNumber" type="text" class="form" v-mask="selectedCountryDDI.phoneMask" @keydown="errorForm.personInvite.phoneNumber = false" @blur='regexPhone("person")' maxlength="25" autocomplete="off" >
                                                        </label>
                                                    </div>

                                                    <div class="form load-phone" v-if="!selectedCountryDDI.changeMask"></div>
                                                </div>
                                                <p class="msg-validate-form msg-error regex-error anime" v-if='validatePhone.person.regex === false || errorForm.personInvite.phoneNumber'>{{getMyLanguage("errors","errors.phoneError")}}</p>
                                                <p class="msg-validate-form msg-error anime" v-if='errorsPost.newRegister === "account_activate_phone_already_exists"'>{{getMyLanguage("errors","errors.phoneRegistered")}}</p>
                                                

                                                <!-- ACCEPTS -->
                                                <div class="accepts">
                                                    <div class="form-group-check">
                                                        <input type="checkbox" id="acceptTerms" class="form-check" v-model="accept.terms">
                                                        <label for="acceptTerms" class="form-check-label" :class="{error: errorForm.acceptsInvite.acceptTerms}"><small>{{ getMyLanguage("purchase-token", "purchase-token.content.box-register.accept-terms") }}</small></label>
                                                        <a :href="`/${flagSelected}/terms-and-conditions`" target="_blank" class="lnk-terms-group-check">{{ getMyLanguage("purchase-token", "purchase-token.content.box-register.accept-terms.lnk") }}</a>
                                                    </div>
                                                    
                                                    <!-- <div class="form-group-check">
                                                        <input type="checkbox" id="acceptNotify" class="form-check" v-model="accept.notify">
                                                        <label for="acceptNotify" class="form-check-label" :class="{error: errorForm.acceptsInvite.acceptNotify}"><small>{{ getMyLanguage("purchase-token", "purchase-token.content.box-register.accept-notify") }}</small></label>
                                                    </div> -->
                                                    
                                                    <div class="form-group-check">
                                                        <input type="checkbox" id="acceptGDPR" class="form-check" v-model="accept.gdpr">
                                                        <label for="acceptGDPR" class="form-check-label" :class="{error: errorForm.acceptsInvite.acceptGdpr}"><small>{{ getMyLanguage("purchase-token", "purchase-token.content.box-register.accept-gdpr") }}</small></label>
                                                    </div>
                                                </div>

                                                <div id="btnRegister" v-if="!accept.terms || !accept.gdpr" @click="validationFormInvite()" class="btn-primary btn-disabled">{{ getMyLanguage("register","register.btn-register") }}</div>
                                                <div id="btnRegister" v-if="accept.terms && accept.gdpr && !load && !errorsPost.general" @click="validationFormInvite()" class="btn-primary">{{ getMyLanguage("register","register.btn-register") }}</div>
                                                <div class="btn-primary load" v-if="load"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="box-error" v-if="errorsPost.newRegister != false">
                                <h3 class="title-secondary">{{ getMyLanguage("box-error", "title-error") }}</h3>
                                <p>{{ getMyLanguage("box-error","general-error") }}</p>
                                <div class="cod-error">
                                    Cód.: {{errorsPost.newRegister}}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>

        <Footer />
        <CookieConsent />
    </div>
</template>

<script>
    const $ = require('jquery')
    window.$ = $

    import Header from '@/components/Header.vue';
    import Footer from '@/components/Footer.vue';
    import CookieConsent from '@/components/CookieConsent.vue';

    import getMyContentLanguage from "@/services/contentLanguage.js";
    import langsList from "@/services/listLanguages.js";
    import countriesList from "@/services/listCountries.js";

    import apiPlatform from '@/config/apiPlatform.js'
  
    export default {
        components: {
            Header,
            Footer,
            CookieConsent
        },

        data(){
            return{
                showContent: false,
                load: false,
                PageEntity: "ecosystem",

                errorsGet: {
                    dataInvite: false
                },
                
                errorsPost: {
                    newRegister: false,
                    country_pep: false,
                    general: false
                },

                validateEmail: {
                    company: {
                        regex: '',
                        status: '',
                        val: ''
                    },

                    person: {
                        regex: '',
                        status: '',
                        val: ''
                    }
                },
                
                validatePhone: {
                    company: {
                        regex: '',
                        status: '',
                        val: ''
                    },

                    person: {
                        regex: '',
                        status: '',
                        val: ''
                    }
                },

                flow: {
                    typeCode: "account_type_person",
                },

                dataCountry: [],
                dataLanguage: [],
                
                flagSelected: "en",
                showFlag: false,
                showFlagCompany: false,

                listLanguagesStatus: false,
                selectedLanguage: {},
                selectedLanguageFlag: false,
                
                listLanguagesCompanyStatus: false,
                selectedLanguageCompany: {},
                selectedLanguageCompanyFlag: false,

                listCountryStatus: false,
                selectedCountry: {},
                selectedCountryFlag: false,
                
                listCountryCompanyStatus: false,
                selectedCountryCompany: {},
                selectedCountryFlagCompany: false,
                
                selectedCountryDDICompany: {
                    changeMask: true,
                    phoneMask: '?',
                    countrySelected: false,
                    flag: ''
                },

                selectedCountryDDI: {
                    changeMask: true,
                    phoneMask: '?',
                    countrySelected: false,
                    flag: ''
                },

                dropListOpened: {
                    languageCompany: false,
                    countryCompany: false,
                    ddiCompany: false,

                    languagePerson: false,
                    countryPerson: false,
                    ddiPerson: false
                },

                newRegister: {
                    person: {
                        pep: false,
                        taxNumber: ""
                    },
                    company: {}

                    // person: {
                    //     pep: false,
                    //     fullName: "",
                    //     email: "",
                    //     countryId: "",
                    //     languageId: "",
                    //     phoneCountryId: "",
                    //     phoneNumber: "",
                    //     taxNumber: ""
                    // },
                    // company: {
                    //     pep: false,
                    //     fullName: "",
                    //     email: "",
                    //     countryId: "",
                    //     languageId: "",
                    //     phoneCountryId: "",
                    //     phoneNumber: "",
                    //     taxNumber: ""
                    // }
                },                

                errorForm: {
                    personInvite: {},
                    acceptsInvite: {
                        acceptTerms: false,
                        acceptNotify: false,
                        acceptGdpr: false
                    },

                    person: false,
                    accepts: false
                },

                accept: {
                    terms: false,
                    notify: false,
                    gdpr: false
                },
            }
        },    
        
        
        async mounted(){
            
            // LANGUAGE
            await apiPlatform.get('/api/v1/platform/language/dne_ecosystem')
            .then(response => {
                this.dataLanguage = response.data
                
                setTimeout(() => {
                    this.orderLangs()
                }, 100);
            })
            .catch(error => {
                this.errorsGet.dataInvite = error.response.data
            })


            // COUNTRY
            await apiPlatform.get('api/v1/platform/country')
            .then(response => {
                this.dataCountry = response.data
                
                setTimeout(() => {
                    this.ordercountries()
                }, 100);
            })
            .catch(error => {
                this.errorsGet.dataInvite = error.response.data
            })


            setTimeout(() => {
                this.showContent = true
            }, 500);
        },


        methods: {
            getMyLanguage(group, code){
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code)
            },

            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code

                document.title = this.getMyLanguage("lp-private-sale","title") + " | DNE Ecosystem";
                document.getElementsByTagName('meta')["description"].content = ''
            },


            // LANGUAGES
            languagesList(code){
                return langsList(code, this.$store.state.SelectLanguage.code)
            },

            orderLangs() {
                this.langsTranslated = [...this.dataLanguage];

                this.langsTranslated.forEach(lang => {
                    lang.translatedName = this.languagesList(lang.code);
                });

                this.langsTranslated.sort((a, b) => {
                    return a.translatedName.toLowerCase().localeCompare(b.translatedName.toLowerCase());
                });
            },
            
            
            // COUNTRIES
            countryTranslate(code){
                return countriesList(code, this.$store.state.SelectLanguage.code)
            },

            ordercountries() {
                this.countriesTranslated = [...this.dataCountry];

                this.countriesTranslated.forEach(lang => {
                    lang.translatedCountry = this.countryTranslate(lang.IsoCode);
                });

                this.countriesTranslated.sort((a, b) => {
                    return a.translatedCountry.toLowerCase().localeCompare(b.translatedCountry.toLowerCase());
                });
            },


            regexEmail(){
                const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

                if (regex.test(this.newRegister.person.email)) {
                    this.validateEmail.person.regex = true
                } else{
                    this.validateEmail.person.regex = false
                }
            },

            // checkEmail(){
            //     if ( this.newRegister.person.checkEmail === this.newRegister.company.email ){
            //         this.newRegister.company.status = true
            //     } else{
            //         this.newRegister.company.status = false
            //     }
            // },

            regexPhone(){
                const regex = /^\+[1-9]\d{8,14}$/;

                let phoneE164Person = this.selectedCountryDDI.ddi + this.validatePhone.person.val
                phoneE164Person = "+" + phoneE164Person.replace(/[^0-9]/g,'')
                
                if (regex.test(phoneE164Person)) {
                    this.validatePhone.person.regex = true
                } else{
                    this.validatePhone.person.regex = false
                }
            },

            changeFlagLanguage(value){
                this.showFlag = false
                this.newRegister.person.languageId = value

                this.showFlag = true
            },

            changeMask(){
                this.selectedCountryDDI.changeMask = false

                setTimeout(() => {
                    this.selectedCountryDDI.changeMask = true
                }, 1000);
                
                setTimeout(() => {
                    document.getElementById('phoneNumber').focus()
                }, 1050);
            },
            
            dropListCountry(status){
                setTimeout(() => {
                    this.listCountryStatus = status
                }, 250)
                
                if ( status ){
                    setTimeout(() => {
                        this.dropListOpened.countryPerson = true
                        document.getElementById('residenceCountry').focus()
                    }, 10);
                } else {
                    this.dropListOpened.countryPerson = false
                }
            },
            
            dropListLanguages(status){
                setTimeout(() => {
                    this.listLanguagesStatus = status
                }, 250)

                if ( status ){
                    setTimeout(() => {
                        this.dropListOpened.languagePerson = true
                        document.getElementById('languageID').focus()
                    }, 10);
                } else {
                    this.dropListOpened.languagePerson = false
                }
            },
            
            
            dropListDDI(status){
                setTimeout(() => {
                    this.selectedCountryDDI.openSelect = status
                }, 250);

                if ( status ){
                    setTimeout(() => {
                        this.dropListOpened.ddiPerson = true
                    }, 10);
                } else {
                    this.dropListOpened.ddiPerson = false
                }
            },


            filterListLanguage() {
                var input, filter, ul, li, a, i, txtValue;
                input = document.getElementById("languageID");
                filter = input.value.toUpperCase();
                ul = document.getElementById("drop-list");
                li = ul.getElementsByTagName("li");
                for (i = 0; i < li.length; i++) {
                a = li[i].getElementsByTagName("a")[0];
                txtValue = a.textContent || a.innerText;
                if (txtValue.toUpperCase().indexOf(filter) > -1) {
                        li[i].style.display = "";
                } else {
                        li[i].style.display = "none";
                }
                }
            },
            
            filterListCountry() {
                var input, filter, ul, li, a, i, txtValue;
                input = document.getElementById("residenceCountry");
                filter = input.value.toUpperCase();
                ul = document.getElementById("drop-list");
                li = ul.getElementsByTagName("li");
                for (i = 0; i < li.length; i++) {
                a = li[i].getElementsByTagName("a")[0];
                txtValue = a.textContent || a.innerText;
                if (txtValue.toUpperCase().indexOf(filter) > -1) {
                        li[i].style.display = "";
                } else {
                        li[i].style.display = "none";
                }
                }
            },


            validationFormInvite(){
                
                // PERSON NAME
                if ( this.newRegister.person.fullName ){
                    var personName = this.newRegister.person.fullName
                    personName = personName.trim().split(' ').length
                    
                    if ( personName < 2 ){
                        this.errorForm.personInvite.fullName = true
                    } else {
                        this.errorForm.personInvite.fullName = false
                    }
                } else {
                    this.errorForm.personInvite.fullName = true
                }


                // EMAIL
                // if ( this.newRegister.person.email === "" || !this.validateEmail.person.regex || !this.validateEmail.person.status ){
                if ( !this.newRegister.person.email || !this.validateEmail.person.regex ){
                    this.errorForm.personInvite.email = true
                } else {
                    this.errorForm.personInvite.email = false
                }


                // LANGUAGE
                if ( this.newRegister.person.languageId === "" ){
                    this.errorForm.personInvite.languageId = true
                } else {
                    this.errorForm.personInvite.languageId = false
                }


                // COUNTRY
                if ( this.newRegister.person.countryId === "" ){
                    this.errorForm.personInvite.countryId = true
                } else {
                    this.errorForm.personInvite.countryId = false
                }


                // PHONE
                if ( this.validatePhone.person.val === "" ){
                    this.errorForm.personInvite.phoneNumber = true
                } else if ( this.validatePhone.person.val.length < this.selectedCountryDDI.phoneMask.length ) {
                    this.errorForm.personInvite.phoneNumber = true
                } else {
                    this.errorForm.personInvite.phoneNumber = false
                }


                // ACCEPTS
                if ( !this.accept.terms ){
                    this.errorForm.acceptsInvite.acceptTerms = true
                } else{
                    this.errorForm.acceptsInvite.acceptTerms = false
                }
                
                // if ( !this.accept.notify ){
                //     this.errorForm.acceptsInvite.acceptNotify = true
                // } else{
                //     this.errorForm.acceptsInvite.acceptNotify = false
                // }
                
                if ( !this.accept.gdpr ){
                    this.errorForm.acceptsInvite.acceptGdpr = true
                } else{
                    this.errorForm.acceptsInvite.acceptGdpr = false
                }

                if ( this.errorForm.acceptsInvite.acceptTerms || this.errorForm.acceptsInvite.acceptGdpr ){
                    this.errorForm.accepts = true
                } else {
                    this.errorForm.accepts = false
                }


                // CKECK VALIDATION COMPLIANCE
                if ( this.errorForm.personInvite.fullName || this.errorForm.personInvite.email || this.errorForm.personInvite.languageId || this.errorForm.personInvite.countryId || this.errorForm.personInvite.phoneNumber ){
                    window.location.href = "#!person"
                    this.errorForm.person = true
                } else {
                    this.errorForm.person = false
                }


                // LAST CKECK
                if ( !this.errorForm.accepts && !this.errorForm.person ){
                    this.register()
                }
            },

            // register(){
            //     window.alert("foi")
            // }

            
            register(){
                this.load = true

                let dataPhonePerson = this.validatePhone.person.val
                this.newRegister.person.phoneNumber = dataPhonePerson.replace(/[^0-9]/g,'')                

                apiPlatform.post("/api/v2/platform/account/lead", this.newRegister)
                .then(response => {
                    this.registerId = response.data.id;
                    const languageRedirect = String(window.location.pathname).split("/")[1];
                    window.location.href = `/${languageRedirect}/account/lead-success/`
                })
                .catch(error => {
                    console.log(error.response.data)

                    this.errorsPost.newRegister = error.response.status
                    console.log(this.errorsPost.newRegister)

                    // if ( error.response.data === "account_activate_email_already_exists" || error.response.data === "account_activate_phone_already_exists" ) {
                    //     this.errorsPost.newRegister = error.response.data
                    // } else if ( error.response.data === "not_allowed_restricted_country_pep" ) {
                    //     this.errorsPost.country_pep = true
                    // } else{
                    //     this.errorsPost.general = error.response.status

                    //     setTimeout(() => {
                    //         this.errorsPost.general = false
                    //     }, 4000);
                    // }

                    setTimeout(() => {
                        this.load = false
                    }, 500);
                })
            },
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected()
                    this.orderLangs()
                    this.ordercountries()
                }
            }
        },

        created () {
            document.title = this.getMyLanguage("lp-private-sale","title") + " | DNE Ecosystem";
            document.getElementsByTagName('meta')["description"].content = ''
            document.head.querySelector('meta[name=robots]').content = 'noindex, nofollow'
        },
    }
</script>

<style src="./custom-register-private-sale.scss" lang="scss" scoped />
<style src="./custom-invite.scss" lang="scss" scoped />

<style lang="scss">
    @import '@/assets/scss/_colors.scss';
    
    .lp-private-sale .box-text h2{
        @media (max-width: 992px){
            font-size: 26px;
            line-height: 26px;    
        }

        @media (min-width: 992px){
            font-size: 40px;
            line-height: 40px;    
        }

        small{
            display: block;
            color: $color-secondary;
        }
    }
</style>