<template>
    <div class="login">
        <!-- LOGIN -->
        <div class="sign-in" v-if="!$store.state.statusLogin">
            <div class="open-box-login" @click="loginActive = true"></div>
            <span class="photo"></span>
            <span class="no-logged">{{ getMyLanguage("login", "login.sign-in") }}</span>
        </div>

        <div class="box-login" v-if="loginActive">
            <div class="close-login" @click="loginActive = false, loginRemember = false, loginOne = true, rememberSend = false"></div>
            <h4 class="title-secondary" v-if="!loginRemember">{{ getMyLanguage("login", "login.access-account") }}</h4>
            <h4 class="title-secondary" v-if="loginRemember">{{ getMyLanguage("login", "login.remember-password") }}</h4>

            <div class="LoginOne" v-if="loginOne">
                <input type="text" class="form formEmailLogin" :class='{error: stepLogin.emailRegex == "error"}' id="emailLogin" @keyup.enter="regexEmail('login')" @keyup="regexEmail()" @blur="regexEmail('check')" :placeholder='getMyLanguage("login","login.placeholder.login")' v-model="sign.username" autocomplete="off" required>
                
                <div class="enterPass">
                    <input type="password" class="form formPassLogin" v-if="!showPass" @keyup.enter="regexEmail('login')" :placeholder='getMyLanguage("login","login.placeholder.password")' v-model="sign.password" autocomplete="off" required>
                    <input type="text" class="form formPassLogin" v-if="showPass" @keyup.enter="regexEmail('login')" :placeholder='getMyLanguage("login","login.placeholder.password")' v-model="sign.password" autocomplete="off" required>

                    <img src="@/assets/images/icons/no-view.svg" class="eyePass" v-if="!showPass" @click="showPass = true" />
                    <img src="@/assets/images/icons/view.svg" class="eyePass" v-if="showPass" @click="showPass = false" />
                </div>

                <div class="btn-remember-password" @click="loginResetPass">{{ getMyLanguage("login", "login.remember-password") }}</div>

                <div class="btn-primary btn-disabled" v-if="stepLogin.emailRegex == 'invalid' || stepLogin.emailRegex == 'error' || sign.username.length <= 5 || sign.password.length <= 7">{{ getMyLanguage("login","login.login") }}</div>
                <div class="btn-primary" @click="loginPlatform()" v-if="stepLogin.emailRegex == 'validated' && !stepLogin.load && sign.username.length > 5 && sign.password.length > 7">{{ getMyLanguage("login","login.login") }}</div>
                <div class="btn-primary btn-loading" v-if="stepLogin.load"></div>
                <!-- <div class="btn-primary nextLogin" @click="loginSMS">Continue</div> -->

                

                <!-- REGISTER -->
                <div class="register">
                    <span class="or"><small>{{ getMyLanguage("login","login.or") }}</small></span>
                    
                    <a :href="`/${flagSelected}/private-sale`" class="btn-primary btn-register" v-if="$store.state.phaseCurrent.phase_type == 'Private'">
                        {{ getMyLanguage("purchase-token","purchase-token.content.phase.interested") }}
                    </a>

                    <a :href="`/${flagSelected}/account/register`" class="btn-primary btn-register" v-if="$store.state.phaseCurrent.phase_type != 'Private'">
                        {{ getMyLanguage("login","login.register-now") }}
                    </a>
                </div>
            </div>

            <!-- <div class="loginTwo" v-if="loginTwo">
                <input type="text" class="form" id="smsCode" placeholder="SMS Code" required>
                <button type="Submit" class="btn-primary">Login</button>
            </div> -->
            
            <div class="loginRemember" v-if="loginRemember && (!rememberSend || !rememberError)">
                <input type="text" class="form formEmailLogin" v-model="emailRemember.email" id="emailRemember" :placeholder='getMyLanguage("login","login.remember-email-registered")' required>
                <button class="btn-primary" @click="rememberPass()" v-if="!emailRemember.load">{{ getMyLanguage("login", "login.remember") }}</button>
                <div class="btn-primary load" v-if="emailRemember.load"></div>
            </div>

            <div class="rememberPass rememberSuccess" v-if="!loginRemember && rememberSend">
                <p>{{getMyLanguage("login","login.remember-password.success")}}</p>
            </div>
            
            <div class="rememberPass rememberError" v-if="loginRemember && rememberError">
                <p>{{getMyLanguage("login","login.remember-password.error")}}</p>
            </div>
        </div>
        <!-- END LOGIN -->
        

        <div class="sign-in" v-if="$store.state.statusLogin">
            <div class="open-box-login" @click="logoutActive = true"></div>
            
            <span class="photo">
                <img src="@/assets/images/users/user.svg">
            </span>

            <span class="no-logged">{{$store.state.profile.profile_full_name}}</span>
        </div>

        
        <div class="box-login" v-if="logoutActive">
            <h4 class="title-secondary">{{$store.state.profile.profile_full_name}}</h4>
            <div class="close-login" @click="logoutActive = false"></div>
            
            <div class="LoginOne" v-if="loginOne">
                <router-link :to="{ name: 'ClientSpace' }" class="btn-box-login btn-box-clientSpace"><span>{{ getMyLanguage("client-space", "client-space.resume.home") }}</span></router-link>
                <!-- <router-link :to="{ name: 'ClientSpaceWallets' }" class="btn-box-login btn-box-wallets"><span>{{ getMyLanguage("client-space", "client-space.resume.wallets") }}</span></router-link> -->
                <!-- <router-link :to="{ name: 'ClientSpaceAccount' }" class="btn-box-login btn-box-account"><span>{{ getMyLanguage("client-space", "client-space.resume.edit") }}</span></router-link> -->
                <!-- <a href="{{this.VUE_APP_INTERNATIONAL_URL}}" class="btn-box-login btn-box-dne-internacional"><span>{{ getMyLanguage("client-space", "client-space.cta-dneinternacional") }}</span></a> -->
                <a :href="linkInternacional" class="btn-box-login btn-box-dne-internacional"><span>{{ getMyLanguage("client-space", "client-space.cta-dneinternacional") }}</span></a>

                <div class="btn-box-login btn-box-logout" @click="logoutPlatform()"><span>{{ getMyLanguage("login", "logout") }}</span></div>

                <!-- <div class="btn-cancel" @click="logoutPlatform()">{{ getMyLanguage("login", "logout") }}</div>
                <div class="btn-cancel btn-loading" v-if="stepLogin.load"></div> -->
            </div>
        </div>
    </div>

    <div class="overlay" v-if="loginActive || logoutActive" @click="loginActive = false, loginRemember = false, loginOne = true, rememberSend = false, logoutActive = false"></div>
</template>


<script>
    const $ = require('jquery')
    window.$ = $

    import apiPlatform from '@/config/apiPlatform.js'

    import getMyContentLanguage from "@/services/contentLanguage.js";

    export default{
        data(){
            return{
                flagSelected: "en",
                VUE_APP_INTERNATIONAL_URL: process.env.VUE_APP_INTERNATIONAL_URL,
                loginActive: false,
                logoutActive: false,
                loginOne: true,
                loginTwo: false,
                loginRemember: false,
                showPass: false,

                emailRemember: {
                    load: false,
                    email: ''
                },

                rememberSend: false,
                rememberError: false,
                dataLogin: '',
                dataAccount: [],

                sign: {
                    username: "",
                    password: ""
                },

                stepLogin: {
                    emailRegex: "",
                    load: false
                },

                validate: {
                    tooltipUsername: false,
                    tooltipPassword: false
                },
                
                newtk: "",
                linkInternacional: "",

                statusUserLogin: {
                    logged: this.$store.state.statusLogin
                }
            }
        },

        async mounted(){
            this.lnkInternational()
        },

        methods: {
            languageSelected(){
                if ( this.$store.state.SelectLanguage.code ){
                    this.flagSelected = this.$store.state.SelectLanguage.code
                }
            },

            getMyLanguage(group, code){
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code)
            },

            regexEmail(type){
                const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

                if (regex.test(this.sign.username)) {
                    if ( type == "login" ){
                        this.stepLogin.emailRegex = "validated"
                        this.loginPlatform()
                    } else {
                        this.stepLogin.emailRegex = "validated"
                    }
                } else{
                    if ( type == "check" ){
                        this.stepLogin.emailRegex = "error"
                    } else {
                        this.stepLogin.emailRegex = "invalid"
                    }
                }
            },

            loginPlatform(){
                const loginPlatform = this.sign;
                this.stepLogin.load = true

                apiPlatform.post("/api/v1/platform/account/login", loginPlatform)
                .then(response => {
                    localStorage.setItem('accessToken',response.data.accessToken);
                    localStorage.setItem('codeLanguage',response.data.profile.account_language_alpha_2);
                    localStorage.setItem('flagLanguage',response.data.profile.account_language_flag);
                    
                    this.flagSelected = localStorage.getItem('codeLanguage')
                    window.location.href = `/${this.flagSelected}/client-space`
                })
                .catch(error => {
                    this.stepLogin.load = false

                    // this.errorMessage = error.message;
                    console.clear()
                    console.log(error.message);

                    $('.LoginOne').addClass('erroLogin');

                    setTimeout(() => {
                        $('.LoginOne').removeClass('erroLogin');
                    }, 6000);

                    let errorsLogin = JSON.stringify(error.response.status)
                    this.loginErrors = errorsLogin
                    // console.log(errorsLogin)

                    if ( errorsLogin == 400 ){
                        this.$toast.error(this.getMyLanguage("login","login-password-error"));
                    } else{
                        this.$toast.error(this.getMyLanguage("login","login-error-authentication"));
                    }

                    setTimeout(this.$toast.clear, 6000)
                })
            },

            logoutPlatform(){
                localStorage.removeItem('accessToken');
                this.$store.commit('loginStatus', false)

                // const languageRedirect = String(window.location.pathname).split("/")[1];
                window.location.href = `/${this.flagSelected}/`
            },

            openBoxLogin(){
                this.loginActive = true
            },

            // closeBoxLogin: function(){
            //     this.loginActive = false,
            //     this.loginOne = true,
            //     this.loginTwo = false,
            //     this.loginRemember = false
            // },

            loginSMS(){
                this.loginOne = false,
                this.loginTwo = true
            },

            loginResetPass(){
                this.loginRemember = true,
                this.loginOne = false,
                this.loginTwo = false
            },


            rememberPass(){
                this.emailRemember.load = true
                
                apiPlatform.post("/api/v1/platform/account/password/reset", this.emailRemember)
                .then(response => {
                    this.registerId = response.data.id;
                    this.rememberSend = true
                    this.loginRemember = false

                })
                .catch(error => {
                    this.emailRemember.load = false
                    this.rememberError = true

                    console.error("There was an error!", JSON.stringify(error));

                    // this.$toast.error(error.response.data);
                    // setTimeout(this.$toast.clear, 6000)
                })
            },

            lnkInternational(){
                this.newtk = localStorage.getItem('accessToken')
                this.linkInternacional = `${this.VUE_APP_INTERNATIONAL_URL}${this.flagSelected}/account/token-auth?tk=${this.newtk}&destiny=client-space`
            }
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        }
    }
</script>

<style src="@/assets/scss/_login.scss" lang="scss" scoped />