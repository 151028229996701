<template>
    <CookieConsent
        :message= texts.message
        :link-label= texts.linkLabel
        :buttonLabel= texts.btnLabel
        :href= urlPrivacy
    />
</template>

<script>
    import CookieConsent from 'vue-cookieconsent-component/src/components/CookieConsent.vue'
    import getMyContentLanguage from "@/services/contentLanguage.js";

    export default {
        components: {
        CookieConsent
        },

        data (){
            return{
                language: "",
                urlPrivacy: '',
                texts: {
                    message: 'Cookies',
                    linkLabel: '',
                    btnLabel: ''
                }
            }
        },

        methods: {
            privacyUrl(){
                this.urlPrivacy = `/${this.language}/privacy`
            },

            getMyLanguage(group, code){
               return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code)
            },

            cookieTexts(){
                let textsLinkLabel = this.getMyLanguage("cookie-consent", "cookie.consent.link.label")
                let textsBtnLabel = this.getMyLanguage("cookie-consent", "cookie.consent.btn.label")

                this.texts.linkLabel = textsLinkLabel
                this.texts.btnLabel = textsBtnLabel
            }
        },

        created() {
            this.privacyUrl();
            this.cookieTexts()
        },

        watch:{
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.language = this.$store.state.SelectLanguage.code
                    this.privacyUrl();
                    this.cookieTexts()
                    this.getMyLanguage();
                }
            }
        }
    }
</script>


<style src="@/assets/scss/_cookie-consent.scss" lang="scss" />