<template>
    <div class="row">
        <!-- <div class="col-12 col-lg-6 resume-client skew-right"> -->
        <div class="col-12 col-lg-9 resume-client skew-right">
            <div class="load" v-if="resumeShow === false"></div>

            <div class="resume-photo">
                <div class="photo">
                    <img src="@/assets/images/users/user.svg">
                </div>

                <div class="status">
                    <div class="status-email validated"></div>
                    <div class="status-kyc pending"></div>
                    <div class="status-phone waiting"></div>
                </div>
            </div>

            <div class="resume-client-cont" >
                <h4>{{$store.state.profile.profile_full_name}}</h4>

                <div class="resume-infs">
                    <p>{{$store.state.profile.profile_email}}</p>
                    <p>+{{$store.state.profile.profile_phone_country_ddi}} {{$store.state.profile.profile_phone_number}}</p>
                    <p>{{$store.state.profile.profile_country_name}}</p>
                </div>
                
                <!-- <router-link :to="{ name: 'ClientSpaceAccount' }" class="btn-tertiary"><span>{{ getMyLanguage("client-space", "client-space.resume.edit") }}</span></router-link> -->
            </div>
        </div>

        <!-- <div class="col-12 col-lg-6 col-xl-3 client-referer-invite skew-right">
            <div class="cont">
                <h4>{{getMyLanguage("client-space","client-space.bonus-invite-title")}}</h4>
                <p>{{getMyLanguage("client-space","client-space.bonus-invite-txt")}}</p>
                <div class="btn-secondary btn-url-copy" @click="copyInvite()">{{getMyLanguage("client-space","client-space.bonus-invite-cta")}}</div>
            </div>
        </div> -->
        
        <!-- <div class="col-12 col-xl-3 client-countdown">
            <ClientSpaceCountdown />
        </div> -->
    </div>
</template>


<script>
//    import apiPlatform from '@/config/apiPlatform.js'
    // import ClientSpaceCountdown from '@/components/ClientSpaceCountdown.vue';
    import getMyContentLanguage from "@/services/contentLanguage.js";

    export default {
        components: {
            // ClientSpaceCountdown
        },

        data(){
            return{
                VUE_APP_PLATFORM_URL: process.env.VUE_APP_PLATFORM_URL,

                dataBalance: {
                    'dne': 45000,
                    'euro': 22500
                },
                resumeShow: false
            }
        },

        async mounted(){            
            this.resumeShow = true
        },

        methods: {
            getMyLanguage(group, code){
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code)
            },

            async copyInvite() {
                try {

                    console.log(this.$store.state.phaseCurrent.phase_type)
                    
                    if ( this.$store.state.phaseCurrent.phase_type == "Private" ){
                        await navigator.clipboard.writeText(`${this.VUE_APP_PLATFORM_URL}en/account/lead/?referrer=${this.$store.state.profile.account_id}`);
                    } else {
                        await navigator.clipboard.writeText(`${this.VUE_APP_PLATFORM_URL}en/account/register/?referrer=${this.$store.state.profile.account_id}`);
                    }

                    this.$toast.success(this.getMyLanguage("receive", "modal-receive.copied"));
                    setTimeout(this.$toast.clear, 3000);    
                }
                
                catch ($e) {
                    this.$toast.success(this.getMyLanguage("receive", "modal-receive.nocopied"));
                    setTimeout(this.$toast.clear, 3000);
                }
            }
        },
        created() {
            this.resumeShow = true
        }
    }
</script>

<style src="@/assets/scss/_clientSpaceResume.scss" lang="scss" scoped />
