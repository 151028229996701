<template>
    <div>
    <Register />

    </div>
</template>

<script>
    import Register from '@/components/Register.vue';
    
    export default {
        components: {
            Register,
        },

        created () {
            document.title = "Register | DNE Ecosystem";
            document.getElementsByTagName('meta')["description"].content = ''
            document.head.querySelector('meta[name=robots]').content = 'noindex, nofollow'
        },
    }
</script>