<template>
    <div class="main">
        <div class="home">
            <div id="dne"></div>
            <Header :dataSource="PageEntity" />

            <div id="clientSpace">
                <section class="client-space">
                    <div class="anc" id="client-space"></div>
                    <div class="container">
                        <ClientSpaceResume />
                    </div>
                </section>

                <a id="anchor-content"></a>

                <section class="client-space-home">
                    <div class="container">
                        <div class="cont-home">
                            <div class="load" v-if="!showContent"></div>
                            <div class="container" v-if="showContent">
                                <h2 class="title-secondary">{{ getMyLanguage("client-space", "client-space.home.title") }}</h2>
                                
                                <div class="row">
                                    <div class="col-12 col-md-6 col-lg-5">
                                        <a :href='linkInternacional' class="lnk-entity" data-aos="fade-right" data-aos-offset="-200" data-aos-delay="200">
                                            <div class="box-lnk box-international">
                                                <figure>
                                                    <span class="icon" data-aos="fade-in" data-aos-offset="-200" data-aos-delay="300"></span>
                                                    <img src="~@/assets/images/home/dne-international.svg" alt="DNE International" data-aos="zoom-out" data-aos-offset="-200">
                                                </figure>
                                                <div class="icon"></div>
                                                <div class="cont-product">
                                                    <p data-aos="fade-right" data-aos-offset="-200" data-aos-delay="100" v-html='getMyLanguage("dne-platform", "home.content.products.international.txt")'></p>
                                                </div>
                                                <div class="cta">
                                                    <div class="btn-secondary">{{ getMyLanguage("client-space","client-space.home.content.cta") }}</div>
                                                </div>
                                            </div>
                                        </a>
                                    </div>

                                    <div class="col-12 col-md-6 col-lg-5">
                                        <a :href="'/' + flagSelected + '/market'" class="lnk-entity" data-aos="fade-right" data-aos-offset="-200" data-aos-delay="200">
                                            <div class="box-lnk box-market">
                                                <figure>
                                                    <span class="icon" data-aos="fade-in" data-aos-offset="-200" data-aos-delay="300"></span>
                                                    <img src="~@/assets/images/home/dne-market.svg" alt="DNE Market" data-aos="zoom-out" data-aos-offset="-200">
                                                </figure>
                                                <div class="icon"></div>
                                                <div class="cont-product">
                                                    <p data-aos="fade-right" data-aos-offset="-200" data-aos-delay="100" v-html='getMyLanguage("dne-platform", "home.content.products.market.txt")'></p>
                                                </div>
                                                <div class="cta">
                                                    <div class="btn-secondary">{{ getMyLanguage("client-space","client-space.home.content.cta") }}</div>
                                                </div>
                                            </div>
                                        </a>
                                    </div>

                                    <div class="col-12 col-md-6 col-lg-5">
                                        <a :href="'/' + flagSelected + '/pay'" class="lnk-entity" data-aos="fade-right" data-aos-offset="-200" data-aos-delay="200">
                                            <div class="box-lnk box-pay">
                                                <figure>
                                                    <span class="icon" data-aos="fade-in" data-aos-offset="-200" data-aos-delay="300"></span>
                                                    <img src="~@/assets/images/home/dne-pay.svg" alt="DNE Pay" data-aos="zoom-out" data-aos-offset="-200">
                                                </figure>
                                                <div class="icon"></div>
                                                <div class="cont-product">
                                                    <p data-aos="fade-right" data-aos-offset="-200" data-aos-delay="100" v-html='getMyLanguage("dne-platform", "home.content.products.pay.txt")'></p>
                                                </div>
                                                <div class="cta">
                                                    <div class="btn-secondary">{{ getMyLanguage("client-space","client-space.home.content.cta") }}</div>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                    
                                    <!-- <div class="col-12 col-md-6 col-lg-5">
                                        <router-link :to="{ name: 'DneBank' }" class="lnk-entity" data-aos="fade-right" data-aos-offset="-200" data-aos-delay="200">
                                            <div class="box-lnk box-bank">
                                                <figure>
                                                    <span class="icon" data-aos="fade-in" data-aos-offset="-200" data-aos-delay="300"></span>
                                                    <img src="~@/assets/images/home/dne-bank.svg" alt="DNE Bank" data-aos="zoom-out" data-aos-offset="-200">
                                                </figure>
                                                <div class="icon"></div>
                                                <div class="cont-product">
                                                    <p data-aos="fade-right" data-aos-offset="-200" data-aos-delay="100" v-html='getMyLanguage("dne-platform", "home.content.products.dne-bank.txt")'></p>
                                                </div>
                                                <div class="cta">
                                                    <div class="btn-secondary">{{ getMyLanguage("client-space","client-space.home.content.cta") }}</div>
                                                </div>
                                            </div>
                                        </router-link>
                                    </div> -->
                                    
                                    <div class="col-12 col-md-6 col-lg-5">
                                        <a :href="'/' + flagSelected + '/exchange'" class="lnk-entity" data-aos="fade-right" data-aos-offset="-200" data-aos-delay="200">
                                            <div class="box-lnk box-exchange">
                                                <figure>
                                                    <span class="icon" data-aos="fade-in" data-aos-offset="-200" data-aos-delay="300"></span>
                                                    <img src="~@/assets/images/home/dne-exchange.svg" alt="DNE Exchange" data-aos="zoom-out" data-aos-offset="-200">
                                                </figure>
                                                <div class="icon"></div>
                                                <div class="cont-product">
                                                    <p data-aos="fade-right" data-aos-offset="-200" data-aos-delay="100" v-html='getMyLanguage("dne-platform", "home.content.products.exchange.txt")'></p>
                                                </div>
                                                <div class="cta">
                                                    <div class="btn-secondary">{{ getMyLanguage("client-space","client-space.home.content.cta") }}</div>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Footer />
        </div>
    </div>
</template>

<script>
    // import apiPlatform from '@/config/apiPlatform.js'

    import Header from '@/components/Header.vue';
    import ClientSpaceResume from '@/components/ClientSpaceResume.vue';
    import Footer from '@/components/Footer.vue';

    import getMyContentLanguage from "@/services/contentLanguage.js";
  
    export default {
        components: {
            Header,
            ClientSpaceResume,
            Footer
        },

        beforeMount(){
            this.loginStatus()
        },

        data(){
            return{
                flagSelected: "en",
                PageEntity: "ecosystem",
                
                VUE_APP_INTERNATIONAL_URL: process.env.VUE_APP_INTERNATIONAL_URL,
                newtk: "",
                linkInternacional: "",
                showContent: false
            }
        },
        
        async mounted(){
            this.lnkInternational()
            this.checkKYC()
        },

        methods: {
            loginStatus(){
                if ( localStorage.getItem('accessToken') === undefined || localStorage.getItem('accessToken') === null){
                    window.location.href = '/'
                }
            },

            checkKYC(){
                if ( this.$store.state.profile.account_status === "Verified" ){
                    this.showContent = true
                    } else{
                        window.location.href = `${this.VUE_APP_INTERNATIONAL_URL}${this.flagSelected}/account/token-auth?tk=${this.newtk}&destiny=client-space`
                }
            },

            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code

                document.title = this.getMyLanguage("seo","title.client-space.account");
                document.getElementsByTagName('meta')["description"].content = ''
            },

            lnkInternational(){
                this.newtk = localStorage.getItem('accessToken')
                this.linkInternacional = `${this.VUE_APP_INTERNATIONAL_URL}${this.flagSelected}/account/token-auth?tk=${this.newtk}&destiny=client-space`
            },

            getMyLanguage(group, code){
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code)
            }
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                    this.lnkInternational();
                }
            }
        },

        created () {
            document.title = this.getMyLanguage("seo","title.client-space.account");
            document.getElementsByTagName('meta')["description"].content = ''
            document.head.querySelector('meta[name=robots]').content = 'noindex, nofollow'
        }
    }
</script>

<style src="./custom-clientSpace.scss" lang="scss" scoped />