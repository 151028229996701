<template>
    <div class="main">
        <div class="home">
            <div id="dne"></div>
            <Header :dataSource="PageEntity" />
            <Slider />


            <!-- ECOSYSTEM -->
            <section class="ecosystem">
                <div class="anc" id="ecosystem"></div>
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-lg-6 ecosystem-cont">
                            <h2 v-html="getMyLanguage('dne-platform','home.content.ecosystem.title')" data-aos="fade-left" data-aos-offset="-200"></h2>
                            <p data-aos="fade-left" data-aos-offset="-200">{{ getMyLanguage('dne-platform','home.content.ecosystem.txt-01') }}</p>
                            <p class="ecosystem-text-italic show-desk" data-aos="fade-left" data-aos-offset="-200">"{{ getMyLanguage('dne-platform','home.content.ecosystem.txt-02') }}"</p>
                        </div>
                        
                        <div class="col-12 col-lg-6">
                            <img :src="`/home/dne-ecosystem-${flagSelected}.png`" alt="DNE Ecosystem" data-aos="zoom-out" data-aos-offset="-200" data-aos-duration="3000">
                            <p class="ecosystem-text-italic show-mobile" data-aos="fade-left" data-aos-offset="-200">"{{ getMyLanguage('dne-platform','home.content.ecosystem.txt-02') }}"</p>
                        </div>
                    </div>
                </div>
            </section>


            <!-- ABOUT US -->
            <section class="about-us">
                <div class="anc" id="about-us"></div>
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-lg-5 col-xl-6">
                            <h2 data-aos="fade-right" data-aos-offset="-200" v-html="getMyLanguage('dne-platform','home.content.about-us.title')"></h2>
                            <p data-aos="fade-right" data-aos-offset="-200" v-html="getMyLanguage('dne-platform','home.content.about-us.text-01')"></p>
                            <p data-aos="fade-right" data-aos-offset="-200" v-html="getMyLanguage('dne-platform','home.content.about-us.text-02')"></p>
                            <p data-aos="fade-right" data-aos-offset="-200" v-html="getMyLanguage('dne-platform','home.content.about-us.text-03')"></p>

                            <div class="downloads">
                                <a :href='"https://cdn.dneecosystem.com/docs/marketing/DNE-Ecosystem-" + flagSelected + ".pdf?v=" + Math.floor(Math.random() * 99999)' target="_blank" class="download">
                                    <span>
                                        {{ getMyLanguage("lp-private-sale","btn-presentation") }}
                                        <small>PDF Download</small>
                                    </span>
                                </a>
                                
                                <a :href='"https://cdn.dneecosystem.com/docs/WhitePaper-" + flagSelected + ".pdf?v=" + Math.floor(Math.random() * 99999)' target="_blank" class="download whitepaper">
                                    <span>
                                        Whitepaper
                                        <small>PDF Download</small>
                                    </span>
                                </a>
                            </div>
                        </div>

                        <div class="col-12 col-lg-7 col-xl-6">
                            <div class="global-ecosystem">
                                <div class="icon-dne" data-aos="zoom-in" data-aos-offset="-200" data-aos-duration="1500"></div>
                                
                                <span class="eco" data-aos="zoom-in" data-aos-offset="-200" data-aos-delay="50">
                                    <span class="icon"></span>
                                    <h6>DNE Token</h6>
                                </span>

                                <span class="eco" data-aos="zoom-out" data-aos-offset="-200" data-aos-delay="150">
                                    <span class="icon"></span>
                                    <h6>{{ getMyLanguage("dne-platform", "home.content.ecosystem.market") }}</h6>
                                </span>

                                <span class="eco" data-aos="zoom-in" data-aos-offset="-200" data-aos-delay="50">
                                    <span class="icon"></span>
                                    <h6>{{ getMyLanguage("dne-platform", "home.content.ecosystem.gateway") }}</h6>
                                </span>

                                <span class="eco" data-aos="zoom-out" data-aos-offset="-200" data-aos-delay="250">
                                    <span class="icon"></span>
                                    <h6>{{ getMyLanguage("dne-platform", "home.content.ecosystem.physical-stores") }}</h6>
                                </span>

                                <span class="eco" data-aos="zoom-out" data-aos-offset="-200" data-aos-delay="250">
                                    <span class="icon"></span>
                                    <h6>{{ getMyLanguage("dne-platform", "home.content.ecosystem.exchange") }}</h6>
                                </span>

                                <span class="eco" data-aos="zoom-in" data-aos-offset="-200" data-aos-delay="50">
                                    <span class="icon"></span>
                                    <h6>{{ getMyLanguage("dne-platform", "home.content.ecosystem.wallets") }}</h6>
                                </span>

                                <span class="eco" data-aos="zoom-out" data-aos-offset="-200" data-aos-delay="250">
                                    <span class="icon"></span>
                                    <h6>{{ getMyLanguage("dne-platform", "home.content.ecosystem.commercial") }}</h6>
                                </span>

                                <span class="eco" data-aos="zoom-in" data-aos-offset="-200" data-aos-delay="50">
                                    <span class="icon"></span>
                                    <h6>{{ getMyLanguage("dne-platform", "home.content.ecosystem.partners") }}</h6>
                                </span>

                                <span class="eco" data-aos="zoom-out" data-aos-offset="-200" data-aos-delay="250">
                                    <span class="icon"></span>
                                    <h6>{{ getMyLanguage("dne-platform", "home.content.ecosystem.users") }}</h6>
                                </span>

                                <span class="eco" data-aos="zoom-in" data-aos-offset="-200" data-aos-delay="50">
                                    <span class="icon"></span>
                                    <h6>{{ getMyLanguage("dne-platform", "home.content.ecosystem.application") }}</h6>
                                </span>

                            </div>
                            
                            <div class="global-ecosystem-bank" data-aos="zoom-in" data-aos-offset="-200">
                                <img src="~@/assets/images/icons/ecosystem-dne-bank.svg" alt="Ecosystem Bank">
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <!-- ENTITIES -->
            <section class="entities">
                <div class="anc" id="entities"></div>
                <div class="container">
                    <h2 class="title-secondary">{{ getMyLanguage("dne-platform", "home.content.products.title") }}</h2>

                    <div class="row holding">
                        <div class="col-12 col-sm-10 col-lg-6">
                            <div class="box-entity box-entity-holding">
                                <figure>
                                    <span class="icon" data-aos="fade-in" data-aos-offset="-200" data-aos-delay="300"></span>
                                    <img src="~@/assets/images/home/dne-holding.svg" alt="DNE Holding" data-aos="zoom-out" data-aos-offset="-200">
                                </figure>

                                <div class="cont-entity">
                                    <p data-aos="fade-right" data-aos-offset="-200" data-aos-delay="100" v-html="getMyLanguage('dne-platform','home.content.products.holding.txt')"></p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row entities-holding">
                        <div class="col-12 col-lg-6">
                            <div class="box-entity box-entity-international">
                                <figure>
                                    <span class="icon" data-aos="fade-in" data-aos-offset="-200" data-aos-delay="300"></span>
                                    <img src="~@/assets/images/home/dne-international.svg" alt="DNE International" data-aos="zoom-out" data-aos-offset="-200">
                                </figure>

                                <div class="cont-entity">
                                    <p data-aos="fade-right" data-aos-offset="-200" data-aos-delay="100" v-html="getMyLanguage('dne-platform','home.content.products.international.txt')"></p>
                                    <a :href="`${VUE_APP_INTERNATIONAL_URL}${flagSelected}/`" class="btn-secondary" target="_blank" data-aos="fade-right" data-aos-offset="-200" data-aos-delay="200"><span>{{ getMyLanguage("dne-platform", "home.content.products.btn.cta-access-website") }}</span></a>
                                </div>
                            </div>
                        </div>

                        <div class="col-12 col-lg-6">
                            <div class="box-entity">
                                <figure>
                                    <span class="icon" data-aos="fade-in" data-aos-offset="-200" data-aos-delay="300"></span>
                                    <img src="~@/assets/images/home/dne-market.svg" alt="DNE Market" data-aos="zoom-out" data-aos-offset="-200">
                                </figure>

                                <div class="cont-entity">
                                    <p data-aos="fade-right" data-aos-offset="-200" data-aos-delay="100" v-html="getMyLanguage('dne-platform','home.content.products.market.txt')"></p>
                                    <a :href="`/${flagSelected}/market`" class="btn-secondary" data-aos="fade-right" data-aos-offset="-200" data-aos-delay="200"><span>{{ getMyLanguage("dne-platform", "home.content.products.btn.cta") }}</span></a>
                                </div>
                            </div>
                        </div>

                        <div class="col-12 col-lg-6">
                            <div class="box-entity">
                                <figure>
                                    <span class="icon" data-aos="fade-in" data-aos-offset="-200" data-aos-delay="300"></span>
                                    <img src="~@/assets/images/home/dne-pay.svg" alt="DNE Pay" data-aos="zoom-out" data-aos-offset="-200">
                                </figure>

                                <div class="cont-entity">
                                    <p data-aos="fade-right" data-aos-offset="-200" data-aos-delay="100" v-html="getMyLanguage('dne-platform','home.content.products.pay.txt')"></p>
                                    <a :href="`/${flagSelected}/pay`" class="btn-secondary" data-aos="fade-right" data-aos-offset="-200" data-aos-delay="200"><span>{{ getMyLanguage("dne-platform", "home.content.products.btn.cta") }}</span></a>
                                </div>
                            </div>
                        </div>
                        
                        <div class="col-12 col-lg-6">
                            <div class="box-entity">
                                <figure>
                                    <span class="icon" data-aos="fade-in" data-aos-offset="-200" data-aos-delay="300"></span>
                                    <img src="~@/assets/images/home/dne-exchange.svg" alt="DNE Exchange" data-aos="zoom-out" data-aos-offset="-200">
                                </figure>
                                
                                <div class="cont-entity">
                                    <p data-aos="fade-right" data-aos-offset="-200" data-aos-delay="100" v-html="getMyLanguage('dne-platform','home.content.products.exchange.txt')"></p>
                                    <a :href="`/${flagSelected}/exchange`" class="btn-secondary" data-aos="fade-right" data-aos-offset="-200" data-aos-delay="200"><span>{{ getMyLanguage("dne-platform", "home.content.products.btn.cta") }}</span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <!-- TOKEN -->
            <section class="dne-token">
                <div class="anc" id="token"></div>
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-lg-6 dne-token-cont skew-right no-skew-mobile">
                            <h2 data-aos="fade-right" data-aos-offset="-200" data-aos-delay="100">DNE <span>T</span>oken</h2> 
                            <p v-html="getMyLanguage('dne-platform','home.content.platform.content')" data-aos="fade-right" data-aos-offset="-200" data-aos-delay="100"></p>
                        </div>

                        <div class="col-12 col-lg-6 dne-token-figure">
                            <img src="~@/assets/images/home/dne-token-coin.png" alt="DNE Token" data-aos="fade-right" data-aos-offset="-200" data-aos-duration="2000">
                        </div>
                    </div>
                </div>
            </section>


            <!-- DOWNLOAD APP -->
            <section class="download-app">
                <div class="anc" id="download-app"></div>
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-lg-6">
                            <div class="download-app-figure">
                                <img src="@/assets/images/home/dne_app.png" alt="DNE App" data-aos="fade-left" data-aos-offset="-200" data-aos-duration="2000">
                            </div>
                        </div>

                        <div class="col-12 col-lg-6 download-app-cont skew-right no-skew-mobile">
                            <h2 data-aos="fade-right" data-aos-offset="-200">DNE <span>A</span>pp</h2>
                            <div v-html="getMyLanguage('dne-platform','home.content.app.content')" data-aos="fade-right" data-aos-offset="-200"></div>

                            <div class="bts-download">
                                <a href="https://play.google.com/store/apps/details?id=com.dneecosystem.app" target="_blank" class="btn-download-app" data-aos="zoom-in" data-aos-offset="-200">
                                    <img src="@/assets/images/home/app-dne-download-android.svg" alt="Google Play">
                                </a>

                                <a href="https://apps.apple.com/pt/app/dne-ecosystem/id6466728703" target="_blank" class="btn-download-app" data-aos="zoom-in" data-aos-offset="-200">
                                    <img src="@/assets/images/home/app-dne-download-ios.svg" alt="App Store">
                                </a>
                                <!-- <a href="https://testflight.apple.com/join/noFcnPvI" target="_blank" class="btn-download-app" data-aos="zoom-in" data-aos-offset="-200">
                                    <img src="@/assets/images/home/app-dne-download-ios.svg" alt="App Store">
                                </a> -->
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <Team />
            <LegalPolicies />

        </div>

        <Footer />
        <CookieConsent />
    </div>
</template>

<script>
    const $ = require('jquery')
    window.$ = $

    import Header from '@/components/Header.vue';
    import Footer from '@/components/Footer.vue';
    import CookieConsent from '@/components/CookieConsent.vue';
    import Slider from '@/components/Slider.vue';
    import Team from '@/components/Team.vue';
    import LegalPolicies from '@/components/LegalPolicies.vue';

    import getMyContentLanguage from "@/services/contentLanguage.js";


    export default {
        components: {
            Header,
            Footer,
            CookieConsent,
            Slider,
            Team,
            LegalPolicies
        },

        data(){
            return{
                VUE_APP_INTERNATIONAL_URL: process.env.VUE_APP_INTERNATIONAL_URL,
                flagSelected: "en",
                PageEntity: "ecosystem",
                dataAccount: [],
                showContent: false
            }
        },

        async mounted(){
            setTimeout(() => {
                this.flagSelected = localStorage.getItem("codeLanguage")
            }, 500);
        
            setTimeout(() => {
                this.showContent = true
            }, 1200);
        },

        methods: {
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code

                document.title = this.getMyLanguage("seo","title.home");
                document.getElementsByTagName('meta')["description"].content = this.getMyLanguage("seo","title.description.home");
                document.getElementsByTagName('meta')["twitter:title"].content = this.getMyLanguage("seo","title.description.home");
                document.getElementsByTagName('meta')["twitter:description"].content = this.getMyLanguage("seo","title.description.home");
                document.querySelector('meta[property="og:title"]').setAttribute("content", this.getMyLanguage("seo","title.home"));
                document.querySelector('meta[property="og:description"]').setAttribute("content", this.getMyLanguage("seo","title.description.home"));
            },

            getMyLanguage(group, code){
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code)
            },

            downloadApp(store){
                if ( store == "google-play" ){
                    // window.location.href = "https://play.google.com/"
                    this.$toast.success(this.getMyLanguage("dne-platform","home.content.app.soon"));
                } else if ( store == "app-store" ){
                    // window.location.href = "https://www.apple.com/pt/app-store/"
                    this.$toast.success(this.getMyLanguage("dne-platform","home.content.app.soon"));
                }
            }
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        },
        
        created () {
            document.title = this.getMyLanguage("seo","title.home");
            document.getElementsByTagName('meta')["description"].content = this.getMyLanguage("seo","title.description.home");
            document.getElementsByTagName('meta')["twitter:title"].content = this.getMyLanguage("seo","title.description.home");
            document.getElementsByTagName('meta')["twitter:description"].content = this.getMyLanguage("seo","title.description.home");
            document.querySelector('meta[property="og:title"]').setAttribute("content", this.getMyLanguage("seo","title.home"));
            document.querySelector('meta[property="og:description"]').setAttribute("content", this.getMyLanguage("seo","title.description.home"));
        },
    }
</script>

<style lang="scss">
    @import '@/assets/scss/_colors.scss';
    @import '@/assets/scss/_fonts.scss';

    .ecosystem h2{
        margin-bottom: 20px;

        small{
            display: block;
            font-family: $font-secondary-bold;
            

            @media (min-width: 992px){
                font-size: 28px;
                line-height: 28px;
            }
            
            @media (max-width: 992px){
                font-size: 22px;
                line-height: 22px;
            }
        }
    }

    .about-us{
        h2{
           margin-bottom: 20px;
           color: $color-text-tertiary;
   
           small{
               display: block;
               font-family: $font-secondary-bold;
   
               @media (min-width: 992px){
                   font-size: 28px;
                   line-height: 28px;
               }
               
               @media (max-width: 992px){
                   font-size: 22px;
                   line-height: 22px;
               }
           }   
        }

        em{
            font-style: normal;
            color: $color-secondary
        }
    }

    .entities{
        .box-entity-holding, .box-entity-international{
            .cont-entity{
                p{
                    strong{
                        font-family: $font-secondary-extrabold;
                        color: $color-primary;
                        text-transform: uppercase;

                        @media (max-width: 576px){
                            font-size: 20px;
                        }
                        
                        @media (min-width: 576px){
                            font-size: 22px;
                        }

                        span{
                            position: relative;
                            color: $color-secondary;
                            
                            &:after{
                                content: '';
                                display: block;
                                width: 100%;
                                height: 3px;
                                background: $color-secondary;
                                position: absolute;
                                left: 0;
                                bottom: 0;
                            }
                        }
                    }
                }
            }
        }
    }
</style>

<style src="./custom-home.scss" lang="scss" scoped />