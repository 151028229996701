<template>
    <div class="main">
        <div class="home">
            <div id="dne"></div>
            <Header :dataSource="PageEntity" />

            <div id="confirmEmail">
                <section class="confirm-email">
                    <div class="skew-right no-skew-mobile">
                        <div class="container">
                            <div class="row">
                                <div class="col-12 col-lg-6">
                                    <h2 class="title-secondary">{{ getMyLanguage("confirm-email", "confirm-email.welcome") }}</h2>

                                    <div class="box-confirm-email">
                                        <div class="icon-confirm-email">
                                            <img src="~@/assets/images/icons/email-confirm.svg">
                                        </div>

                                        <div class="msg msg-success" v-if='$store.state.phaseCurrent.phaseType === "Private"' ><p>{{ getMyLanguage("confirm-email", "confirm-email.phasePrivate.msg") }}</p></div>
                                        <div class="msg msg-success" v-if='showContent && $store.state.phaseCurrent.phaseType != "Private"' ><p>{{ getMyLanguage("confirm-email", "confirm-email.phasePublic.msg") }}</p></div>
                                        <div class="msg msg-attention">
                                            <p v-html='getMyLanguage("confirm-email", "confirm-email-spam")'></p>
                                        </div>
                                        
                                        <div class="cordially">{{ getMyLanguage("confirm-email", "confirm-email.cordially") }}</div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
        <Footer />
        <CookieConsent />
    </div>
</template>

<script>
    import Header from '@/components/Header.vue';
    import Footer from '@/components/Footer.vue';
    import CookieConsent from '@/components/CookieConsent.vue';

    import getMyContentLanguage from "@/services/contentLanguage.js";
  
    export default {
        components: {
            Header,
            Footer,
            CookieConsent
        },

        data (){
            return {
                PageEntity: "ecosystem",
            }
        },

        async mounted(){
            this.showContent = true
        },

        methods: {
            getMyLanguage(group, code){
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code)
            }
        },

        created () {
            document.title = this.getMyLanguage("seo","title.account.confirmEmail");
            document.getElementsByTagName('meta')["description"].content = ''
            document.head.querySelector('meta[name=robots]').content = 'noindex, nofollow'
        },
    }
</script>

<style src="./custom-confirm-email.scss" lang="scss" scoped />