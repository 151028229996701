<template>
    <div class="select-language">
        <div class="selected-lang">
            <div class="close-select" v-if="selectStatus" @click="selectStatus = false"></div>
            <div class="flag" @click="selectStatus = true">
                <div class="load-flag" v-if="!flagShow"></div>
                <img v-if="flagShow" :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${flagSelected}/flags`">
            </div>
        </div>

        <div class="flags-language" v-if="selectStatus">
            <div class="close-select" v-if="selectStatus" @click="selectStatus = false"></div>
            <h4 class="title-secondary">{{getMyLanguage("SelectLanguage","title")}}</h4>

            <div class="list-languages">
                <div class="flag" v-for="(lang, index) in langsTranslated" :key="lang.code + index" @click="changeLanguage(lang.code, lang.flag)">
                    <img :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${lang.flag}/flags`">
                    <span>{{ languagesList(lang.code) }}</span>
                </div>
            </div>
        </div>
    </div>
    
    <div class="overlay" v-if="selectStatus" @click="selectStatus = false"></div>
</template>


<script>
    import getMyContentLanguage from "@/services/contentLanguage.js";
    import langsList from "@/services/listLanguages.js";

    export default{
        data: function () {
            return {
                flagSelected: "",
                selectStatus: false,
                flagShow: false,

                langsTranslated: []
            }
        },
        
        methods: {
            getMyLanguage(group, code){
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code)
            },

            languagesList(code){
                return langsList(code, this.$store.state.SelectLanguage.code)
            },

            orderLangs() {
                this.langsTranslated = [...this.$store.state.allLanguages];

                this.langsTranslated.forEach(lang => {
                    lang.translatedName = this.languagesList(lang.code);
                });

                this.langsTranslated.sort((a, b) => {
                    return a.translatedName.toLowerCase().localeCompare(b.translatedName.toLowerCase());
                });
                
                setTimeout(() => {
                    this.flagShow = true
                }, 500);
            },
            
            changeLanguage(parLanguage, parFlag){
                this.flagShow = false
                this.selectStatus = false

                localStorage.setItem('codeLanguage',parLanguage);
                localStorage.setItem('flagLanguage',parFlag);

                var url = window.location.pathname;
                var newUrl = url.replace(/\/[a-z]{2}\//, '/');
                var isHome = String(window.location.pathname).split("/")

                if ( isHome.length > 2 ){
                    newUrl = '/' + parLanguage + newUrl;
                } else {
                    newUrl = '/' + parLanguage
                }
                
                history.pushState(null, null, newUrl);

                this.flagSelected = parFlag
                this.$store.commit('preLanguage',parLanguage)
                this.$store.commit('preLanguageFlag',parFlag)

                this.orderLangs()

                setTimeout(() => {
                    this.flagShow = true
                }, 500);
            },
        },

        async mounted () {
            this.flagSelected = localStorage.getItem('flagLanguage')

            setTimeout(() => {
                const languageURL = String(window.location.pathname).split("/")[1];
                const filterFlag = this.$store.state.allLanguages.filter(langs => langs.code == languageURL)[0].flag


                if ( !localStorage.getItem("flagLanguage") || localStorage.getItem("flagLanguage") != filterFlag ){
                    this.changeLanguage(languageURL, filterFlag)
                }

                if ( localStorage.getItem("codeLanguage") != languageURL ){
                    this.changeLanguage(languageURL, filterFlag)
                }

                this.flagShow = true
            }, 1500);
        },

        watch: {
            '$store.state.allLanguages': {
                immediate: true,
                handler() {
                    this.orderLangs()
                }
            },
        }
    }
</script>


<style src="@/assets/scss/_selectLanguage.scss" lang="scss" scoped />