<template>
    <div class="main success-lead">
        <div class="home">
            <div id="dne"></div>
            <Header :dataSource="PageEntity" />

            <div id="confirmEmail">
                <section class="confirm-email">
                    <div class="skew-right no-skew-mobile">
                        <div class="container">
                            <div class="row">
                                <div class="col-12 col-lg-6">
                                    <h2 class="title-secondary">{{ getMyLanguage("confirm-email", "confirm-email.welcome") }}</h2>

                                    <div class="box-confirm-email confirm-email-success">
                                        <div class="icon-confirm-email">
                                            <img src="~@/assets/images/icons/register-success.svg">
                                        </div>

                                        <div class="msg msg-success"><p>{{ getMyLanguage("confirm-email", "register-lead-success") }}</p></div>
                                        <!-- <div class="msg msg-attention">
                                            <p v-html='getMyLanguage("confirm-email", "confirm-email-spam")'></p>
                                            <a :href='"/" + flagSelected + "/account/login"' class="btn-primary" v-html='getMyLanguage("confirm-email","cta-create-account")'></a>
                                        </div> -->
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
        <Footer />
        <CookieConsent />
    </div>
</template>

<script>
    import Header from '@/components/Header.vue';
    import Footer from '@/components/Footer.vue';
    import CookieConsent from '@/components/CookieConsent.vue';
    
    import getMyContentLanguage from "@/services/contentLanguage.js";
  
    export default {
        components: {
            Header,
            Footer,
            CookieConsent
        },

        data (){
            return {
                // flagSelected: "en",
                PageEntity: "ecosystem",
            }
        },
        
        async mounted(){
            this.showContent = true
        },

        methods: {
            getMyLanguage(group, code){
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code)
            },

            // languageSelected(){
            //     this.flagSelected = this.$store.state.SelectLanguage.code
            // },
        },

        // watch: {
        //     '$store.state.SelectLanguage.code': {
        //         immediate: true,
        //         handler() {
        //             this.languageSelected();
        //         }
        //     }
        // },

        created () {
            document.title = this.getMyLanguage("seo","title.account.confirmEmail");
            document.getElementsByTagName('meta')["description"].content = ''
            document.head.querySelector('meta[name=robots]').content = 'noindex, nofollow'
        }
    }
</script>

<style src="./custom-confirm-email.scss" lang="scss" scoped />
<style scoped>
    .icon-confirm-email img{width: 140px !important; height: auto;}
    .btn-primary{
        height: 80px;
        line-height: 22px;
    }
</style>