<template>
    <div class="main">
        <div class="home">
            <div id="dne"></div>
            <Header :dataSource="PageEntity" />

            <div id="clientSpace">

                <div class="stepMobile" id="StepClientSpaceMobile">
                    <StepClientSpace />
                </div>

                <section class="client-registering">
                    <div class="skew-right no-skew-mobile">

                        <div class="load load-200" v-if="!showContent"></div>

                        <!-- ERROR GET -->
                        <div class="container" v-if="showContent && errorsGet.dataRememberPass != false">
                            <div class="box-error">
                                <h3 class="title-secondary">{{ getMyLanguage("box-error", "title-error") }}</h3>
                                <p>{{getMyLanguage("box-error", errorsGet.dataRememberPass)}}</p>
                                <div class="cod-error">
                                    Cód.: {{errorsGet.dataRememberPass}}
                                </div>
                            </div>
                        </div>
                        

                        <!-- ERROR POST -->
                        <div class="container" v-if="showContent && errorsPost.dataRememberPass != false">
                            <div class="box-error">
                                <h3 class="title-secondary">{{ getMyLanguage("box-error", "title-error") }}</h3>
                                <p>{{getMyLanguage("box-error", errorsPost.dataRememberPass)}}</p>
                                <div class="cod-error">
                                    Cód.: {{errorsPost.dataRememberPass}}
                                </div>
                            </div>
                        </div>

                        <div class="container" v-if="showContent && errorsGet.dataRememberPass === false">
                            <h2 class="title-secondary">{{ getMyLanguage("identification", "identification.salutation") }} {{dataRememberPass.fullName}}</h2>
                            <div class="row">
                                <div class="col-12 col-sm-7 col-lg-4 rememberPass-cont">
                                    <div class="remember-pass">
                                        <p v-if='dataRememberPass.inviteType === "change_password"'>{{ getMyLanguage("identification","rememberPass.alert.change-password") }}</p>
                                        <p v-if='dataRememberPass.inviteType === "password_recovery"'>{{ getMyLanguage("identification","rememberPass.alert.password-recovery") }}</p>
                                    </div>

                                    <form method="post" @submit.prevent="validateForm()">

                                        <div class="forcePassMsgs anime" :class="validatePass.force">
                                            <p v-if='validatePass.force === 0' v-html='getMyLanguage("errors", "errors.force.0")'></p>
                                            <p v-if='validatePass.force === "weak"' v-html='getMyLanguage("errors", "errors.force.weak")'></p>
                                            <p v-if='validatePass.force === "averageUppercase"' v-html='getMyLanguage("errors", "errors.force.averageUppercase")'></p>
                                            <p v-if='validatePass.force === "averageNumber"' v-html='getMyLanguage("errors", "errors.force.averageNumber")'></p>
                                            <p v-if='validatePass.force === "strong"' v-html='getMyLanguage("errors", "errors.force.strong")'></p>
                                            <p v-if='validatePass.force === "excellent"' v-html='getMyLanguage("errors", "errors.force.excellent")'></p>
                                        </div>

                                        <div class="forcePass anime" :class="validatePass.force" v-if="validatePass.force != 0">
                                            <span class="anime">Weak</span>
                                            <span class="anime">Average</span>
                                            <span class="anime">Strong</span>
                                            <span class="anime">Excellent</span>
                                        </div>

                                        <div class="enterPass">
                                            <input type="password" v-if="!showPass" v-model="validatePass.val" class="form formPassword" id="formPass" :placeholder='this.getMyLanguage("identification", "identification.password")' autocomplete="off" maxlength="16" @keyup="passForce()" >
                                            <input type="text" v-if="showPass" v-model="validatePass.val" class="form formPassword" id="formPass" :placeholder='this.getMyLanguage("identification", "identification.password")' autocomplete="off" maxlength="16" @keyup="passForce()" >
                                            <img src="@/assets/images/icons/view.svg" class="eyePass" v-if="!showPass" @click="showPass = true" />
                                            <img src="@/assets/images/icons/no-view.svg" class="eyePass" v-if="showPass" @click="showPass = false" />
                                        </div>

                                        <div class="enterPass">
                                            <input type="password" v-if="!showPass" v-model="newPass.password" id="formConfirm" class="form formPassword" :class="{ invalid: noConfirmPass }" :placeholder='this.getMyLanguage("identification", "identification.confirmPassword")' autocomplete="off" maxlength="16" @keyup="passConfirm()">
                                            <input type="text" v-if="showPass" v-model="newPass.password" id="formConfirm" class="form formPassword" :class="{ invalid: noConfirmPass }" :placeholder='this.getMyLanguage("identification", "identification.confirmPassword")' autocomplete="off" maxlength="16" @keyup="passConfirm()">
                                        </div>

                                        <div class="msgError">
                                            <span v-if='errors.minCharacters'>{{ getMyLanguage("errors", "errors.minCharacters") }}</span>
                                            <span v-if='errors.passWeak'>{{ getMyLanguage("errors", "errors.weakPassword") }}</span>
                                            <span v-if='errors.different'>{{ getMyLanguage("errors", "errors.differentPass") }}</span>
                                        </div>

                                        <button class="btn-primary btn-disabled" v-if='!load && validatePass.force != "excellent"'>{{ getMyLanguage("client-space", "client-space.account.btn.save") }}</button>
                                        <button type="submit" id="btnRegister" class="btn-primary" v-if='!load && validatePass.force === "excellent"'>{{ getMyLanguage("client-space", "client-space.account.btn.save") }}</button>
                                        <div class="btn-primary load" v-if="load"></div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
        <Footer />
        <CookieConsent />
    </div>
</template>

<script>    
    import Header from '@/components/Header.vue';
    import Footer from '@/components/Footer.vue';
    import CookieConsent from '@/components/CookieConsent.vue';

    import getMyContentLanguage from "@/services/contentLanguage.js";

    import apiPlatform from '@/config/apiPlatform.js'
  
    export default {
        components: {
            Header,
            Footer,
            CookieConsent
        },
        data(){
            return{
                showContent: false,
                PageEntity: "ecosystem",
                
                dataRememberPass: [],

                errorsGet: {
                    dataRememberPass: false
                },

                errorsPost: {
                    dataRememberPass: false
                },

                successPass: false,

                acceptTerms: false,
                showPass: false,

                newPass: {
                    password: ''
                },

                validatePass: {
                    equalFields: false,
                    val: '',
                    force: 0
                },

                noConfirmPass: '',
                load: false,

                errors: {
                    minCharacters: false,
                    different: false,
                    passWeak: false,
                }
            }
        },
        
        beforeMount(){
            const tokenValue = localStorage.getItem('accessToken')
            
            if ( tokenValue != undefined ){
                localStorage.removeItem('accessToken');
                this.$router.go()
            }
        },

        async mounted(){
            await apiPlatform.get(`/api/v1/platform/account/password/${this.$route.params.registerId}`)
            .then(response => {
                this.dataRememberPass = response.data
            })
            .catch(error => {
                this.errorsGet.dataRememberPass = error.response.data
            })

            this.newPass.registerId = this.dataRememberPass.id
            
            setTimeout(() => {
                this.showContent = true
            }, 500);
        },


        methods: {
            passForce(){
                this.validatePass.val = document.getElementById("formPass").value;

                if ( (this.validatePass.val.length >= 4) && (this.validatePass.val.length <= 7) ){
                    this.validatePass.force = "weak"
                } else{
                    this.validatePass.force = 0
                }
                
                if ( this.validatePass.val.length >= 8 ){
                    this.validatePass.force = 'averageUppercase'
                }
                
                if ( (this.validatePass.val.length >= 8) && (this.validatePass.val.match(/[A-Z]/)) ){
                    this.validatePass.force = 'averageNumber'
                }

                if ( (this.validatePass.val.length >= 8) && (this.validatePass.val.match(/[0-9]/)) && (this.validatePass.val.match(/[A-Z]/)) ){
                    this.validatePass.force = 'strong'
                }
                
                if ( (this.validatePass.val.length >= 8) && (this.validatePass.val.match(/[0-9]/)) && (this.validatePass.val.match(/[A-Z]+/)) && (this.validatePass.val.match(/[^a-zA-Z0-9]+/g)) ){
                    this.validatePass.force = 'excellent'
                }
            },

            passConfirm(){
                console.log(this.newPass.password)
                if ( this.validatePass.val != this.newPass.password ){
                    this.noConfirmPass = true
                } else{
                    this.noConfirmPass = false
                    this.errors.different = false
                }
            },

            validateForm(){
                this.validatePass.val = document.getElementById("formPass").value;

                if ( this.validatePass.val.length < 8 ){
                    this.errors.minCharacters = true
                }  else{
                    this.errors.minCharacters = false
                }
                
                // if ( this.validatePass.force != 'strong' && this.validatePass.force != 'excellent' ){
                if ( this.validatePass.force != 'excellent' ){
                    this.errors.passWeak = true
                } else{
                    this.errors.passWeak = false
                }
                
                if ( this.validatePass.val != this.newPass.password ){
                    this.errors.different = true
                } else{
                    this.errors.different = false
                }

                if ( !this.errors.minCharacters && !this.errors.passWeak && !this.errors.different ){
                    this.changePass()
                    this.load = true
                }
            },

            changePass(){
                apiPlatform.post(`/api/v1/platform/account/password/${this.$route.params.registerId}`, this.newPass)
                .then(response => {
                    this.registerId = response.data.id;
                    this.successPass = true

                    setTimeout(() => {
                        const languageRedirect = String(window.location.pathname).split("/")[1];
                        window.location.href = '/' + languageRedirect + '/account/login'
                    }, 5000);

                })
                .catch(error => {
                    this.load = false
                    this.errorsGet.dataRememberPass = error.response.data[0]
                })
            },
            
            getMyLanguage(group, code){
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code)
            }
        },

        created () {
            document.title = this.getMyLanguage("seo","title.account.rememberPass");
            document.getElementsByTagName('meta')["description"].content = ''
            document.head.querySelector('meta[name=robots]').content = 'noindex, nofollow'
        },
    }
</script>

<style src="./custom-activate.scss" lang="scss" scoped />

<style scoped>
    .forcePass span{animation: showValidatePass .4s alternate;}
    .forcePassMsgs p{animation: showValidatePassMsg .4s alternate;}

    .btn-primary{width: 100%;}

    @keyframes showValidatePass{
        0% {top: 20px;}
        100% {top: 0;}
    }
    @keyframes showValidatePassMsg{
        0% {opacity: 0; top: 5px;}
        100% {opacity: 1; top: 0;}
    }
</style>