import pt from '@/locales/contents/pt.json';
import br from '@/locales/contents/br.json';
import en from '@/locales/contents/en.json';
import es from '@/locales/contents/es.json';
import ar from '@/locales/contents/ar.json';
import ru from '@/locales/contents/ru.json';
import fr from '@/locales/contents/fr.json';
import it from '@/locales/contents/it.json';
import de from '@/locales/contents/de.json';

export default function getMyFavouriteLanguage(group, code, storeLanguage) {

    let lang = [];

    switch (storeLanguage) {
        case "pt":
            lang = pt;
            break;

        case "br":
            lang = br;
            break;

        default:
        case "en":
            lang = en;
            break;

        case "es":
            lang = es;
            break;

        case "ar":
            lang = ar;
            break;

        case "ru":
            lang = ru;
            break;

        case "fr":
            lang = fr;
            break;

        case "it":
            lang = it;
            break;

        case "de":
            lang = de;
            break;
    }

    return lang.filter((item) => item.group === group).filter((item) => item.code === code).map(item => item.value).join();
}