<template>
    <div class="main">
        <div class="home register-page">
            <div id="dne"></div>
            <Header :dataSource="PageEntity" />

            <section class="confirm-email">
                <Register :dataOrigin="origin" />
            </section>
        </div>

        <Footer />
        <CookieConsent />
    </div>
</template>

<script>
    import Header from '@/components/Header.vue';
    import Register from '@/components/Register.vue';
    import Footer from '@/components/Footer.vue';
    import CookieConsent from '@/components/CookieConsent.vue';
    
    import getMyContentLanguage from "@/services/contentLanguage.js";
  
    export default {
        components: {
            Header,
            Register,
            Footer,
            CookieConsent
        },

        data (){
            return {
                flagSelected: "en",
                PageEntity: "ecosystem",
                origin: "page"
            }
        },

        methods: {
            getMyLanguage(group, code){
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code)
            },

            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code
            },
        },

        created () {
            document.title = this.getMyLanguage("register","register.title") + " | DNE Ecosystem";
            document.getElementsByTagName('meta')["description"].content = ''
            document.head.querySelector('meta[name=robots]').content = 'noindex, nofollow'
        },
    }
</script>

<style src="./custom-invite.scss" lang="scss" scoped />