<template>
    <div class="main">
        <div class="termsPolicies">
            <div id="dne"></div>
            <Header :dataSource="PageEntity" />

            <section class="content-terms">
                <div class="container">
                    <div class="box">
                        <div class="scroll-text">
                            <h1 class="title-secondary">{{getMyLanguage("terms-policies","terms-conditions.title")}}</h1>
                            <p><small>LAST UPDATED: JUNE 13, 2022</small></p>

                            <h3>ECOSISTEMA DNE ETVE SL Terms and Conditions</h3>

                            <p>These Terms and Conditions regulate the access and use of the website DNEECOSYSTEM.com and app. DNE ECOSYSTEM, whose domains belong to <strong>ECOSISTEMA DNE ETVE SL</strong>, which is responsible for operating and managing the operation.</p>
                            <p>The use of the WebSite by third parties implies, from now on, the full and unreserved acceptance of these Terms and Conditions of Use (“TCU”) by its Users.</p>
                            <p>&nbsp;</p>
                            
                            <ul>
                                <li>1. General Provisions</li>
                                <li>1.1. Description of Service. ECOSISTEMA DNE ETVE SL, a feature within the DNEECOSYSTEM.com web domain and App (“App”), and related functionality permits eligible App users (“DNE Users”) to have access to the various domains and Apps of the various entities that are part of DNE ECOSYSTEM, namely DNE International Sagl; DNE Exchange; DNE Market and DNE Pay. The "DNE Users" are giving their consent and agreement so that the registration data, including user code and respective password created in their registration on the DNE ECOSYSTEM are the same in accessing the various entities of the DNE ECOSYSTEM, not excluding that in each of them have to follow the operation instructions and rules of each one</li>
                                <li>1.2. Contractual Agreement. These terms and conditions, and any others referenced or incorporated herein, constitute a legally binding contract between you, as a DNE User, and the DNEECOSYSTEM.com entity described below (“Relevant Entity”) that provides the access service to DNE Entities (the “Agreement”). This Agreement governs your access to and use of DNE Entities.</li>
                                <li>1.2.1 Relevant Entity. For purposes of this Agreement, the Relevant Entity providing the access to DNE Entities service is ECOSISTEMA DNE ETVE SL. Notwithstanding, the Relevant Entity for customers residing in each country’s respective territories, is the same entity that offers you the DNEECOSYSTEM.com App Services via the App. Throughout this Agreement, references to “DNEECOSYSTEM.com,” “we,” “us,” and “our” mean the Relevant Entity, as applicable.</li>
                            </ul>
                            
                            <ul>
                                <li>2. Eligibility. To be eligible to use DNEECOSYSTEM.com, you must:</li>
                                <li>- Be an existing App user in compliance with the App’s terms and conditions (“App Agreement”);</li>
                                <li>- Be at least 18 years of age or the legal age of majority in the jurisdiction in which you reside;</li>
                                <li>- Not be a resident or national of North Korea and Iran (“FATF Blacklist”);</li>
                                <li>- Not be subject to any sanctions imposed by any government, including without limitation those imposed by the United Nations, the European Union, any EU country, the UK Treasury, or the U.S. Office of Foreign Assets Control (“OFAC”);</li>
                                <li>- Read, understand, and agree to our Privacy Notice and Cookie Policy;</li>
                                <li>- Agree to be contacted and/or to receive an email to confirm acceptance of creation of a digital account profile; and</li>
                                <li>- Confirm to accept to share his data with the owner Relevant Entity and/or its affiliated companies and to be contacted accordingly for commercial purposes.</li>
                                <li>Notwithstanding your meeting the eligibility criteria above, we reserve the right to refuse your request to use and access the DNEECOSYSTEM.com service, including your ability to open an Account, to suspend your access to Entities of DNE ECOSYSTEM, and terminate your registration at any time in our sole discretion. Further, we reserve the right to change the eligibility criteria at any time. If we become aware that you are an ineligible DNE User or otherwise determine that you are in violation of any applicable terms and conditions, we may delete your registration immediately without notice and ban you from using any of our or our Affiliates’ products and services. In addition to the other rights set forth herein and to those that may be available to us, you agree that you will indemnify and hold harmless DNEECOSYSTEM.com and its Affiliates from any legal or monetary consequences that arise from any unauthorized use, including without limitation, all uses associated with an ineligible DNE User.</li>
                            </ul>

                            <ul>
                                <li>3. Rights of DNEECOSYSTEM.com. We reserve the right to and at any time, without liability to you to:</li>
                                <li>- Refuse your request to open a registration;</li>
                                <li>- Change the eligibility criteria for registration or use of website DNEECOSYSTEM.com and app.DNE ECOSYSTEM, including the DNE Entities;</li>
                                <li>- Close, suspend, limit, restrict, or terminate your access to DNE Entities, the App, or other products and services provided by our Entities or Affiliates;</li>
                                <li>- Close, suspend, limit, restrict, or terminate your access to DNE Entities, the App, or other products and services provided by ourselves or any other of our Affiliates if you remain inactive, without any movement/transaction and/or access and without any DNE Entities for a period equal to or greater than 3 months; and</li>
                                <li>- Change, update, remove, cancel, suspend, disable, or discontinue any features, component, content.</li>
                            </ul>

                            <ul>
                                <li>4. Prohibited Activities. By accessing and using DNEECOSYSTEM.com and App, you agree that you will not do any of the following:</li>
                                <li>- Engage in any activity that would violate or assist in the violation of any law, statute, regulation, or ordinance;</li>
                                <li>- Violate or breach any terms or conditions in this Agreement, any other agreement or set of applicable terms and conditions, our Privacy Notice, or our Cookie Policy;</li>
                                <li>- Provide us with any false, inaccurate, or misleading information or otherwise attempt to defraud us;</li>
                                <li>- Attempt to gain unauthorized access to our network, system, or websites or DNE Entities other than your own;</li>
                                <li>- Decompile, disassemble, or otherwise reverse engineer our App, any products or services of our Entities or any other related product or service, program, or system or in any way infringe on our intellectual property rights including, but not limited to, copyrights, patents, trademarks, or trade secrets;</li>
                                <li>- Engage in any form of data mining, indexing, scraping, surveying, or otherwise with respect to our App, domain, DNE Entities, or any other related product and service, program, or system;</li>
                                <li>- Unduly burden or hinder the functionality and/or operation of any aspect of our App, domain, DNE Entities, or any other product and service, program, or system offered, maintained, or provided by us or our affiliates.</li>
                            </ul>

                            <ul>
                                <li>5. Suspension and Termination</li>
                                <li>Account. We reserve the right to suspend, restrict, or terminate your user Account if:</li>
                                <li>- We are required to do so by a court order, government order, or a facially valid subpoena;</li>
                                <li>- We reasonably suspect you of engaging in Prohibited Activities as described in this Agreement; or</li>
                                <li>- We reasonably suspect you of otherwise being in violation of this Agreement or other applicable terms and conditions for products and services offered by DNEECOSYSTEM.com or its Entities and affiliates.</li>
                                <li>- As the owner of the WebSite, may, at any time, prohibit its access to third parties, namely for the purpose of repair or maintenance. Additionally, DNE ECOSYSTEM will have the right to terminate it at any time in the event of any circumstance that, in its opinion, justifies it.</li>
                                <li>Upon termination of your User Account, you are entitled to recover your digital assets in accordance with the App’s terms and conditions and this Agreement, unless we are prohibited from doing so by law or a court order or where we reasonably suspect that such funds were obtained through fraud or any unlawful means or in connection with any criminal activities.</li>
                                <li>This Agreement terminates immediately upon you closing your Account or our termination of your User Account, but such termination shall not prevent any party from seeking remedies against any other party for any breach of this Agreement occurring prior to termination.</li>
                            </ul>

                            <ul>
                                <li>6. Contact</li>
                                <li>If you have any questions regarding DNEECOSYSTEM.com or need assistance in connection with the suspension or termination of your User Account, please contact us at SUPPORT@DNEECOSYSTEM.com.</li>
                            </ul>

                            <ul>
                                <li>7. Miscellaneous Provisions</li>
                                <li>Indemnity. You will defend, indemnify, and hold harmless DNEECOSYSTEM.com, its Entities and affiliates, and each of their respective employees, officers, directors, and representatives from and against any claims, damages, losses, liabilities, costs, and expenses (including reasonable attorneys’ fees) arising out of or related to your breach of this Agreement, your violation of any statute, regulation, or ordinance, or the rights of any third party.</li>
                                <li>LIMITATION OF LIABILITY</li>
                                <li>NOTWITHSTANDING ANY PROVISIONS TO THE CONTRARY IN THIS AGREEMENT OR OTHERWISE MADE AVAILABLE TO YOU FROM TIME TO TIME, IN NO EVENT SHALL EITHER PARTY BE LIABLE TO THE OTHER FOR ANY TYPE OF INCIDENTAL, SPECIAL, EXEMPLARY, PUNITIVE, INDIRECT, OR CONSEQUENTIAL DAMAGES, INCLUDING, BUT NOT LIMITED TO, LOST REVENUE, LOST PROFITS, REPLACEMENT OF GOODS, LOSS OF TECHNOLOGY, LOSS OF DATA, OR INTERRUPTION OR LOSS OF USE OF SERVICE OR EQUIPMENT EVEN IF SUCH PARTY WAS ADVISED OF THE POSSIBILITY OF SUCH DAMAGES AND WHETHER ARISING UNDER THEORY OF CONTRACT, TORT, STRICT LIABILITY, OR OTHERWISE.</li>
                                <li>Except as provided in this Agreement and to the extent permitted by law, we disclaim all other representations or warranties, express or implied, made to you, our Entities or affiliates, or any other person including, without limitation, any warranties regarding quality, suitability, merchantability, fitness for a particular purpose, title, non-infringement, or otherwise, regardless of any course of dealing, custom, or usage of trade, of any service or any goods provided incidental to DNEECOSYSTEM.com and App under this Agreement.</li>
                                <li>Our liability in respect of representations and warranties that cannot be excluded under this Agreement is limited to, at our option, any one of resupplying, replacing, or repairing, or paying the cost of thereof, the services in respect of which the breach occurred.</li>
                                <li>IN NO EVENT WILL OUR AGGREGATE LIABILITY FOR ANY LOSS OR DAMAGE ARISING IN CONNECTION WITH DNE ECOSYSTEM EXCEED EUR €100 OR THE TOTAL FEES THAT YOU PAID US FOR ITS USE DURING THE TWELVE (12) MONTH PERIOD IMMEDIATELY PRECEDING THE EVENT GIVING RISE TO THE CLAIM FOR LIABILITY. THE FOREGOING LIMITATIONS OF LIABILITY SHALL APPLY TO THE FULLEST EXTENT PERMITTED BY LAW IN THE APPLICABLE JURISDICTION.</li>
                            </ul>

                            <ul>
                                <li>8. Relationship</li>
                                <li>DNEECOSYSTEM.com is not your trustee, investment advisor, or fiduciary. By providing the App and User Account service to you, DNEECOSYSTEM.com does not become a trustee, investment advisor, or form any sort of fiduciary relationship with you. We have no trust or other obligations with respect of your digital assets other than those expressly specified in this Agreement.</li>
                            </ul>
                            
                            <ul>
                                <li>9. Entire Agreement</li>
                                <li>This Agreement (together with the Privacy Notice and Cookie Policy) represents the entire agreement between you and us with respect to DNEECOSYSTEM.com and supersedes all prior representations, understandings, agreements, or communications between you and us, whether written or verbal, including any statements published in our whitepaper regarding DNE TOKEN.</li>
                            </ul>

                            <ul>
                                <li>10. Amendments</li>
                                <li>We may amend or modify this Agreement from time to time by posting the updated Agreement on the App or in accordance with the App terms and conditions and such amendment or modification shall take effect immediately. Your continued use of User Account constitutes your acceptance of the updated Agreement; if you do not agree with the updated Agreement, your sole and exclusive remedy is to discontinue use of your User Account and DNE ECOSYSTEM.</li>
                            </ul>

                            <ul>
                                <li>11. Severability</li>
                                <li>If any provision, in whole or in part, of this Agreement shall be determined to be invalid or unenforceable by a court of competent jurisdiction or a governmental body, such provision shall be changed and interpreted to accomplish its objectives to the greatest extent possible under applicable law and the validity and enforceability of any other provision of this Agreement shall not be affected.</li>
                                <li>All provisions of this Agreement which by their nature extend beyond its termination, including, without limitation, provisions pertaining to suspension and termination, shall survive such termination.</li>
                                <li>The delay or failure of one party to require performance of any provision of this Agreement shall not constitute a waiver of that party’s right to require performance at any time thereafter. Further, the waiver of one party to seek recovery for the other party’s non-performance shall not constitute a waiver by the former party to seek recovery for any subsequent non-performance.</li>
                            </ul>

                            <ul>
                                <li>12. Assignment</li>
                                <li>You may not assign, delegate, or transfer any rights or obligations under this Agreement without our prior written consent. Any such attempted assignment, delegation, or transfer shall be null and void. We reserve the right to assign our rights without restriction, including without limitation to affiliates or subsidiaries, or to any successor in interest of any business associated with the App or DNEECOSYSTEM.com.</li>
                            </ul>

                            <ul>
                                <li>13. Third Party Rights</li>
                                <li>Other than the Entities and affiliates of DNEECOSYSTEM.com, a person who is not a party to this Agreement shall have no right under this Agreement.</li>
                            </ul>

                            <ul>
                                <li>14. Governing Law</li>
                                <li>This Agreement shall be governed by, construed, and enforced in accordance with the laws of Spain. Any dispute, controversy, difference, or claim arising out of or relating to this Agreement including the existence, validity, interpretation, performance, breach, or termination thereof or any dispute regarding non-contractual obligations arising out of or relating to the same shall be referred to and finally resolved by a court of competent jurisdiction sitting in Madrid and must be conducted in Spanish.</li>
                            </ul>

                            <ul>
                                <li>15. Force Majeure</li>
                                <li>We shall not be liable for any delay, error, interruption, or failure to perform any obligation under this Agreement where the delay or failure is directly or indirectly resulting from any cause beyond our control including, but not limited to i) acts of God, nature, court, or government; ii) failure or interruption in private or public telecommunications networks, communication channels, or information systems; iii) acts or omissions of a party for whom we are not responsible; iv) delay, failure, interruption in, or unavailability of third-party services and sites; v) strikes, lockouts, labor disputes, war, terrorist acts, or riots; and vi) viruses, malware, other malicious computer code, or the hacking of any part of the App or DNEECOSYSTEM.com.</li>
                            </ul>

                            <ul>
                                <li>16. Disclosures</li>
                                <li>The regulatory status of digital assets is currently unsettled, varies among jurisdictions, and is subject to significant uncertainty. It is possible that in the future, certain laws, regulations, policies, or rules relating to digital assets, blockchain technology, or blockchain applications may be implemented which would directly or indirectly affect or restrict the services that we provide to you.</li>
                                <li>YOU ARE RESPONSIBLE FOR DETERMINING WHETHER THE USE OF DNEECOSYSTEM.COM AND ANY OF OUR RELATED SERVICES IS LEGAL IN YOUR JURISDICTION AND YOU SHALL NOT USE THEM SHOULD SUCH USE BE ILLEGAL IN YOUR JURISDICTION. IF YOU ARE UNCERTAIN, PLEASE SEEK INDEPENDENT LEGAL ADVICE.</li>
                                <li>We may be forced to suspend, discontinue, or to change aspects of DNEECOSYSTEM.com and any of our services in any jurisdictions without notice and for whatever reason if demanded by regulatory authorities. In such instances, digital assets in your wallet Account may be frozen for an indefinite period of time until the matter is resolved.</li>
                            </ul>

                            <ul>
                                <li>17. Cybersecurity</li>
                                <li>Digital accounts and assets may be subject to expropriation, theft and/or fraud; hackers and other malicious groups or organizations may attempt to interfere with our network and/or system in various ways including malware attacks, denial of service attacks, consensus-based attacks, Sybil attacks, smurfing, and spoofing which may result in the loss of your digital assets or the loss of your ability to access or control the same. In such an event, we do not guarantee any remedy, refund, or compensation.</li>
                            </ul>

                            <ul>
                                <li>18. Source Code Weakness</li>
                                <li>There is a risk that the DNEECOSYSTEM.com, App or any of our products and services may unintentionally include weakness or bugs in the source code which may adversely affect you.</li>
                            </ul>

                            <ul>
                                <li>19. Insurance</li>
                                <li>The digital assets held in your User Account are not protected by any government - backed insurance scheme including, but not limited to, the Central Bank or any other Insurance company.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <Footer />
        <CookieConsent />
    </div>
</template>


<script>
    import Header from '@/components/Header.vue';
    import Footer from '@/components/Footer.vue';
    import CookieConsent from '@/components/CookieConsent.vue';
    import getMyContentLanguage from "@/services/contentLanguage.js";

    export default {
        components: {
            Header,
            Footer,
            CookieConsent
        },

        data (){
            return {
                PageEntity: "ecosystem"
            }
        },
        
        methods: {
            getMyLanguage(group, code){
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code)
            },
        },

        created () {
            document.title = this.getMyLanguage("seo","title.termsConditions");
            document.getElementsByTagName('meta')["description"].content = this.getMyLanguage("seo","title.description.termsConditions")
        },
    }
</script>

<style src="./custom-terms.scss" lang="scss" scoped />

<style>
    html, body, #app, .main{
        height: 100%;
    }
</style>