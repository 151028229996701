<template>
    <footer :class="{ 'has-install': hasInstallApp }">
        <div class="container">
            <div class="row">
                <div class="col-12 col-lg-5">
                    <div class="copyright skew-right">
                        <p>© Copyright DNE Ecosystem</p>
                        <p>ECOSISTEMA DNE ETVE, SOCIEDAD LIMITADA<br>{{ getMyLanguage("footer","company") }}</p>
                    </div>
                </div>

                <div class="col-12 col-lg-7">
                    <div class="dne-footer skew-left">
                        <div class="footer-cont">
                            <img src="@/assets/images/header/logo-dne.svg" class="logo-footer" alt="DNE Digital New Economy">
                        
                            <div class="social">
                                <a href="https://www.facebook.com/dneplatform" class="facebook" target="_blank"></a>
                                <a href="https://www.instagram.com/dneplatform/" class="instagram" target="_blank"></a>
                                <a href="https://www.linkedin.com/company/dneplatform/about/" class="linkedin" target="_blank"></a>
                                <a href="https://twitter.com/dneplatform" class="twitter" target="_blank"></a>
                                <a href="https://www.youtube.com/@dneplatform" class="youtube" target="_blank"></a>
                            </div>

                            <a :href="`${VUE_APP_INTERNATIONAL_URL}${flagSelected}/company-registration`" target="_blank" class="so-fit">
                                <span>{{ getMyLanguage("footer","affiliate") }}</span>
                                <img src="~@/assets/images/icons/logo-so-fit.svg" alt="SO-FIF">
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>

    <InstallApp @close-install-app="handleInstallAppClosed" v-if="isMobile" />
</template>


<script>
   import getMyContentLanguage from "@/services/contentLanguage.js";
   import InstallApp from "@/components/InstallApp.vue";

    export default{
        components: {
            InstallApp
        },

        data(){
            return{
                VUE_APP_INTERNATIONAL_URL: process.env.VUE_APP_INTERNATIONAL_URL,
                flagSelected: "en",

                isMobile: false,
                hasInstallApp: true
            }
        },

        async mounted (){
            this.checkIfMobile();

            setTimeout(() => {
                const isClosed = JSON.parse(sessionStorage.getItem("showInstallApp"))
                
                if ( isClosed == false ){
                    this.hasInstallApp = false
                }
            }, 500);
        },
        
        methods: {
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code
            },

            getMyLanguage(group, code){
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code)
            },

            checkIfMobile() {
                const isNarrowScreen = window.innerWidth <= 768;
                const isMobileDevice = /Mobi|Android/i.test(navigator.userAgent);
                
                this.isMobile = isNarrowScreen && isMobileDevice;

                if ( !this.isMobile ){
                    this.hasInstallApp = false
                }
            },

            handleInstallAppClosed(newValue) {
                this.hasInstallApp = newValue;
            },
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        }
    }
</script>

<style src="@/assets/scss/_footer.scss" lang="scss" scoped />