<template>
    <div class="main">
        <div class="home">
            <div id="dne"></div>
            <Header :dataSource="PageEntity" />

            <div id="clientSpace">
                <div class="stepMobile" id="StepClientSpaceMobile">
                    <StepClientSpace />
                </div>

                <section class="client-space">
                    <div class="anc" id="client-space"></div>
                    <div class="container">
                        <ClientSpaceResume />
                    </div>
                </section>

                <a id="anchor-content"></a>

                <!-- EDIT ACCOUNT -->
                <section class="account">
                    <div class="anc" id="edt-account"></div>
                    <div class="container">
                        <form method="post" @submit.prevent="update">
                            <div class="profile">
                                <div class="row">
                                    <div class="col-12">
                                        <h2 class="title-secondary">{{ getMyLanguage("client-space", "client-space.account.title") }}</h2>
                                    </div>

                                    <div class="col-12 col-sm-6 col-lg-4">
                                        <label>
                                            {{ getMyLanguage("client-space", "client-space.account.lbl.firstname") }}
                                            <input type="text" class="form" id="first-name" v-model="dataAccount.personName" required>
                                        </label>
                                    </div>

                                    <div class="col-12 col-sm-6 col-lg-4">
                                        <label>
                                            {{ getMyLanguage("client-space", "client-space.account.lbl.lastname") }}
                                            <input type="text" class="form" id="last-name" v-model="dataAccount.personSurname" required>
                                        </label>
                                    </div>

                                    <div class="col-12 col-sm-6 col-lg-4">
                                        <label class="">
                                            <div class="changePassword" @click="changePassModal()">
                                                <span>{{getMyLanguage("client-space","client-space.account.btn-change-password")}}</span>
                                            </div>
                                        </label>
                                    </div>

                                    <div class="col-12 col-sm-6 col-lg-4">
                                        <div class="phone">
                                            <div class="formPhone active">
                                                <label class="lbl-phone lbl-status-pending" v-if="selectedCountryDDI.changeMask === true">
                                                    {{ getMyLanguage("client-space", "client-space.account.lbl.phone") }}
                                                    
                                                    <div class="select-ddi" v-if="selectedCountryDDI.changeMask === true">
                                                        <div class="selected-ddi">
                                                            <div class="open-select-ddi" @click="selectedCountryDDI.openSelect = true"></div>
                                                            <div class="close-select-ddi" v-if="selectedCountryDDI.openSelect === true" @click="selectedCountryDDI.openSelect = false"></div>
                                                            <div class="flag">
                                                                <img :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${selectedCountryDDI.flag}/flags`" v-if="selectedCountryDDI.flag != ''">
                                                            </div>
                                                            <span class="ddi">+{{selectedCountryDDI.ddi}}</span>
                                                        </div>
                                                        <ul class="list-ddi" v-if="selectedCountryDDI.openSelect === true">
                                                            <div class="scroll">
                                                                <li v-for="(ddiList,index) in dataCountry" :key="index" @click="(dataAccount.PhoneCountryId = ddiList.Id), (selectedCountryDDI.phoneMask = ddiList.PhoneMask), (this.changeMask()), (selectedCountryDDI.ddi = ddiList.DDI), (selectedCountryDDI.flag = ddiList.Flag), (selectedCountryDDI.openSelect = false)">
                                                                    <div class="flag">
                                                                        <img class="anime" :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${ddiList.Flag}/flags`">
                                                                    </div>
                                                                    <span class="name-country">{{ddiList.Name}}</span>
                                                                    <span class="ddi" >(+{{ddiList.DDI}})</span>
                                                                </li>
                                                            </div>
                                                        </ul>
                                                        <input type="text" class="form" id="phone" v-model="dataAccount.personPhoneNumber" v-mask="selectedCountryDDI.phoneMask" required>
                                                    </div>
                                                </label>
                                                
                                                <div class="load" v-if="selectedCountryDDI.changeMask === false"></div>
                                            </div>
                                            
                                            <div class="btn-tertiary">
                                                <span>{{ getMyLanguage("client-space", "client-space.account.lbl.phone.validate") }}</span>
                                                <small>{{ getMyLanguage("client-space", "client-space.account.lbl.phone.validate-send") }}</small>
                                            </div>
                                            <div class="sms-code">
                                                <input type="text" class="form" id="sms-code" placeholder="SMS Code">
                                                <div class="btn-send-form-arrow"></div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-12 col-sm-6 col-lg-4">
                                        <label class="lbl-kyc">
                                            {{ getMyLanguage("client-space", "client-space.account.lbl.identification") }}
                                            
                                            <div class="load" v-if="!dataAccountShow"></div>
                                            
                                            <div class="kyc" v-if="dataAccountShow">

                                                <!-- VERIFIED -->
                                                <div class="icon kyc-verified" v-if="dataAccount.accountStatus === 'Verified'">
                                                    <span>{{ getMyLanguage("client-space", "client-space.account.lbl.identification.status-verified") }}</span>
                                                </div>
                                                <div class="expiration-date" v-if="dataAccount.accountStatus === 'Verified'">
                                                    <small>{{ getMyLanguage("client-space", "client-space.account.lbl.identification-expirationDate") }}</small>
                                                    <span>25/06/2022</span>
                                                </div>

                                                <!-- NO VERIFIED -->
                                                <div class="icon kyc-pending" v-if="dataAccount.accountStatus != 'Verified'">
                                                    <span>{{ getMyLanguage("client-space", "client-space.account.lbl.identification.status-pending") }}</span>
                                                </div>
                                                <div class="btn-tertiary" v-if="dataAccount.accountStatus != 'Verified'">
                                                    <span>{{ getMyLanguage("client-space", "client-space.account.lbl.identification-now") }}</span>
                                                    <small>{{ getMyLanguage("client-space", "client-space.account.lbl.identification-now-selfie") }}</small>
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-lg-4">
                                        <label class="lbl-photo">
                                            <div class="photo">
                                                <div class="photo-user">
                                                    <img src="@/assets/images/users/user.svg">
                                                </div>
                                                <div class="btn-tertiary">
                                                    <span>{{ getMyLanguage("client-space", "client-space.account.lbl.change-photo") }}</span>
                                                    <small>{{ getMyLanguage("client-space", "client-space.account.lbl.change-photo-update") }}</small>
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-lg-4">
                                        <label class="lbl-status-validated">
                                            {{ getMyLanguage("client-space", "client-space.account.lbl.email") }}
                                            <div class="form form-disabled">{{dataAccount.personEmail}}</div>
                                        </label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-lg-4">
                                        <label class="lbl-status-validated">
                                            {{ getMyLanguage("client-space", "client-space.account.lbl.country") }}
                                            <select id="language" class="form" v-model="dataAccount.countryId">
                                                <option selected disabled hidden>{{dataAccount.countryName}}</option>
                                                <option v-for="(itemCountry,index) in dataCountry" :key="index" :value="itemCountry.id">{{itemCountry.name}}</option>
                                            </select>
                                        </label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-lg-4">
                                        <label>
                                            {{ getMyLanguage("client-space", "client-space.account.lbl.language") }}
                                            <select id="language" class="form" v-model="dataAccount.languageId">
                                                <option selected disabled hidden>{{dataAccount.languageName}}</option>
                                                <option v-for="(itemLanguage,index) in dataLanguage" :key="index" :value="itemLanguage.id">{{itemLanguage.name}}</option>
                                            </select>
                                        </label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-lg-4">
                                        <label>
                                            {{ getMyLanguage("client-space", "client-space.account.lbl.currency") }}
                                            <select id="language" class="form" v-model="dataAccount.assetId">
                                                <option selected disabled hidden>{{dataAccount.assetName}}</option>
                                                <option v-for="(itemAsset,index) in dataAsset" :key="index" :value="itemAsset.id">{{itemAsset.name}}</option>
                                            </select>
                                        </label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-lg-4">
                                        <label>
                                            {{ getMyLanguage("client-space", "client-space.account.lbl.birthdate") }}
                                            <input type="date" class="form" v-model="dataAccount.personDOB" required>
                                        </label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-lg-4">
                                        <label>
                                            {{ getMyLanguage("client-space", "client-space.account.lbl.gender") }}
                                            <select id="gender" class="form" v-model="dataAccount.personGenderId">
                                                <option v-for="(itemGender,index) in dataGender" :key="index" :value="itemGender.id">{{ getMyLanguage("client-space", itemGender.extra) }}</option>
                                            </select>
                                        </label>
                                    </div>

                                    <div class="col-12 col-sm-6 col-lg-4">
                                        <label>
                                            {{ getMyLanguage("client-space", "client-space.account.lbl.taxNumber") }}
                                            <input type="text" class="form" v-model="dataAccount.personTaxNumber" required>
                                        </label>
                                    </div>

                                    <div class="col-12 col-sm-6 col-lg-4">
                                        <label>
                                            {{ getMyLanguage("client-space", "client-space.account.lbl.referrer") }}
                                            <div class="form form-disabled">{{dataAccount.accountReferrerName}}</div>
                                        </label>
                                    </div>

                                    <div class="col-12 col-sm-6 col-lg-4">
                                        <label>
                                            {{ getMyLanguage("client-space", "client-space.account.lbl.indicate") }}
                                            <div class="indication" @click="copyUrl()"><span>{{ getMyLanguage("client-space", "client-space.account.copy-my-link") }}</span></div>
                                        </label>
                                    </div>

                                </div>

                                <div class="row">
                                    <div class="col-12 col-save">
                                        <button type="submit" class="btn-primary">{{ getMyLanguage("client-space", "client-space.account.btn.save") }}</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </section>


                <!-- MODAL CHANGE PASSWORD -->
                <div class="modal modal-change-password" v-if="changePass.modal">
                    <div class="modal-cont">
                        <div class="modal-close" @click="changePass.modal = false"></div>
                        <div class="row">
                            <div class="change-pass-content" v-if="changePass.confirmChange">
                                <div class="box">
                                    <p><strong>{{getMyLanguage("client-space","client-space.account.modal.really-change-password")}}</strong></p>
                                </div>
                                <div class="buttons">
                                    <button @click="changePassword()" v-if="!changePass.load" class="btn-primary">{{getMyLanguage("client-space","client-space.account.modal.btn-yes")}}</button>
                                    <button @click="changePass.modal = false" class="btn-cancel">{{getMyLanguage("client-space","client-space.account.modal.btn-no")}}</button>
                                </div>
                            </div>

                            <div class="load load-110" v-if="emailRemember.load"></div>

                            <!-- CHANGE SUCCESS -->
                            <div class="change-pass-content change-success" v-if="changePass.successChange && !emailRemember.load">
                                <div class="box">
                                    <p><strong>{{getMyLanguage("client-space","client-space.account.modal.sent-email-instructions-change-password")}}</strong></p>
                                    <div class="attention"><p><small v-html='getMyLanguage("confirm-email","confirm-email-spam")'></small></p></div>
                                </div>
                            </div>

                            <!-- CHANGE ERROR -->
                            <div class="change-pass-content change-error" v-if="changePass.errorChange && !emailRemember.load">
                                <div class="box" v-html='getMyLanguage("client-space","client-space.account.modal.changePass-error")'></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
    import Header from '@/components/Header.vue';
    import ClientSpaceResume from '@/components/ClientSpaceResume.vue';
    import Footer from '@/components/Footer.vue';

    import getMyContentLanguage from "@/services/contentLanguage.js";
    
    import apiPlatform from '@/config/apiPlatform.js'
    
    import moment from 'moment';

    export default {
            components: {
            Header,
            ClientSpaceResume,
            Footer
        },

        beforeMount(){
            this.loginStatus()
        },

        data(){
            return{
                flagSelected: "en",
                PageEntity: "ecosystem",

                dataAccount: [],
                dataAccountShow: false,
                dataCountry: [],
                dataLanguage: [],
                dataAsset: [],
                dataGender: [],
                dataGenderAccount: [],

                emailRemember: {
                    load: false,
                    email: this.$store.state.profile.profile_email
                },

                residenceCountry: {},
                
                dataDDI: {},
                selectedCountry: {},
                selectedCountryDDI: {
                    changeMask: false,
                    countrySelected: true,
                    phoneMask: ''
                },

                changePass: {
                    modal: false,
                    email: '',
                    confirmChange: true,
                    successChange: false
                }
            }
        },
        
        async mounted(){
            const resumeClient = await apiPlatform.get('/api/v1/platform/account/person/')
            this.dataAccount = resumeClient.data
            this.dataAccountShow = true
            this.dataAccount.personDOB = this.dateTime(this.dataAccount.personDOB)

            const resumeClientCountry = await apiPlatform.get('/api/v1/platform/country')
            this.dataCountry = resumeClientCountry.data
            this.dataDDI = resumeClientCountry.data.filter(item => item.Id === this.dataAccount.personPhoneCountryId)[0]
            this.changeMaskStart();

            const resumeClientLanguage = await apiPlatform.get('/api/v1/platform/language/dne_ecosystem')
            this.dataLanguage = resumeClientLanguage.data

            const resumeClientAsset = await apiPlatform.get('/api/v1/pay/asset/type/fiat')
            this.dataAsset = resumeClientAsset.data

            const resumeClientGender = await apiPlatform.get('/api/v1/public/attributes/codes/person_gender')
            this.dataGender = resumeClientGender.data

            // this.goTop();
        },

        methods: {
            loginStatus(){
                if ( localStorage.getItem('accessToken') === undefined || localStorage.getItem('accessToken') === null){
                    window.location.href = '/'
                }
            },

            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code
                moment.locale(this.flagSelected);

                document.title = this.getMyLanguage("seo","title.client-space.account");
                document.getElementsByTagName('meta')["description"].content = ''
            },

            getMyLanguage(group, code){
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code)
            },

            goTop(){
                window.location.hash="anchor-content";
            },

            changeMaskStart(){
                this.selectedCountryDDI.changeMask = false

                setTimeout(() => {
                    this.selectedCountryDDI.changeMask = true
                    this.selectedCountryDDI.phoneMask = this.dataDDI.phoneMask

                    this.selectedCountryDDI.ddi = this.dataDDI.ddi
                    this.selectedCountryDDI.flag = this.dataDDI.flag
                    this.selectedCountry.name = this.dataDDI.name
                }, 1000);
            },

            changeMask(){
                this.selectedCountryDDI.changeMask = false

                setTimeout(() => {
                    this.selectedCountryDDI.changeMask = true
                }, 1000);
            },

            changePassModal(){
                this.changePass.email = this.dataAccount.personEmail
                this.changePass.modal = true
                this.changePass.confirmChange = true
                this.changePass.successChange = false
            },

            changePassword(){
                this.changePass.confirmChange = false
                this.emailRemember.load = true
                
                apiPlatform.post("/api/v1/platform/account/password/reset", this.emailRemember)
                .then(response => {
                    this.registerId = response.data.id;
                    
                    setTimeout(() => {
                        this.emailRemember.load = false
                        this.changePass.successChange = true
                    }, 500);
                })
                .catch(error => {
                    this.emailRemember.load = false
                    this.changePass.errorChange = true

                    console.error("There was an error!", JSON.stringify(error));
                })
            },

            dateTime(value) {
                const format = this.flagSelected === 'en' ? 'YYYY/MM/DD' : 'DD/MM/YYYY';
                return moment(value).format(format);
            },

            update(){
                const update = this.dataAccount;
                apiPlatform.put("/api/v1/platform/account", update)
                .then(response => {
                    this.registerId = response.data.id;
                    
                    window.location.href = `/${this.flagSelected}/client-space-account-success/`
                })
                .catch(error => {
                    this.errorMessage = error.message;
                    console.error("There was an error!", error);
                    
                    this.$toast.error(this.errorMessage);

                    setTimeout(this.$toast.clear, 3000)
                });
            },

            copyUrl(){
                this.$toast.success(`Copied`);
                setTimeout(this.$toast.clear, 3000)
            },
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        },

        created () {
            document.title = this.getMyLanguage("seo","title.client-space.account");
            document.getElementsByTagName('meta')["description"].content = ''
            document.head.querySelector('meta[name=robots]').content = 'noindex, nofollow'
        },
    }
</script>

<style src="./custom-clientSpace.scss" lang="scss" scoped />
<style src="./custom-clientSpaceAccount.scss" lang="scss" scoped />