<template>
    <div class="main">
        <div class="home">
            <div id="dne"></div>
            <Header :dataSource="PageEntity" />

            <div id="confirmEmail">
                <section class="confirm-email">
                    <div class="container">
                        <div class="row">
                            <div class="col-12 col-lg-6">

                                <h2 class="title-secondary">{{ getMyLanguage("confirm-email", "confirm-email.welcome") }}</h2>
                                <div class="box-confirm-email">
                                    <div class="icon-confirm-email">
                                        <img src="~@/assets/images/icons/email-confirm.svg">
                                    </div>
                                    
                                    <div class="msg msg-success">
                                        {{ getMyLanguage("confirm-email", "confirm-email-invite.msg") }}

                                        <div class="resend">
                                            <p v-if="!emailLoad">{{ getMyLanguage("confirm-email","confirm-email-invite.resend.txt") }} <span @click='resendMsg("email")'>{{ getMyLanguage("confirm-email","confirm-email-invite.resend.cta") }}</span></p>
                                            <p v-if="emailLoad">{{ getMyLanguage("confirm-email","confirm-email-invite.sending") }}</p>
                                        </div>
                                    </div>

                                    <div class="msg msg-attention">
                                        <p v-html='getMyLanguage("confirm-email", "confirm-email-spam")'></p>
                                    </div>

                                    <div class="sms">
                                        <p>{{ getMyLanguage("sms","sms.continue-register") }}</p>
                                        <div class="btn-primary" v-if="!smsLoad" @click='resendMsg("sms")'>{{ getMyLanguage("sms","sms.send") }}</div>
                                        <div class="btn-primary load" v-if="smsLoad"></div>
                                    </div>
                                    
                                    <div class="cordially">{{ getMyLanguage("confirm-email", "confirm-email.cordially") }}</div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
        <Footer />
        <CookieConsent />
    </div>
</template>

<script>
    import Header from '@/components/Header.vue';
    import Footer from '@/components/Footer.vue';
    import CookieConsent from '@/components/CookieConsent.vue';
    import getMyContentLanguage from "@/services/contentLanguage.js";

    import apiPlatform from '@/config/apiPlatform.js'
  
    export default {
        components: {
            Header,
            Footer,
            CookieConsent
        },

        data(){
            return{
                PageEntity: "ecosystem",
                smsLoad: false,
                emailLoad: false
            }
        },    

        async mounted(){
            this.showContent = true
        },
        
        methods: {
            getMyLanguage(group, code){
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code)
            },

            resendMsg(type){
                if ( type == "sms" ){
                    this.smsLoad = true
                } else{
                    this.emailLoad = true
                }

                apiPlatform.post("/api/v1/platform/account/confirm/resend", {"inviteId": this.$route.params.registerId, "messageType": type})
                .then(response => {
                    console.log(response)
                    console.clear()

                    if ( type == "sms" ){
                        this.$toast.success(this.getMyLanguage("sms","sms.sent"));
                    } else{
                        this.$toast.success(this.getMyLanguage("confirm-email","confirm-email-invite.email-resend"));
                    }

                    setTimeout(() => {
                        if ( type == "sms" ){
                            this.smsLoad = false
                        } else{
                            this.emailLoad = false
                        }

                        setTimeout(this.$toast.clear, 6000)
                    }, 1000);
                })
                .catch(error => {
                    console.log(error)
                    
                    this.$toast.success(this.getMyLanguage("box-error","general-error"));
                    
                    setTimeout(() => {
                        if ( type == "sms" ){
                            this.smsLoad = false
                        } else{
                            this.emailLoad = false
                        }

                        setTimeout(this.$toast.clear, 6000)
                    }, 1000);
                })
                
            }
        },

        created () {
            document.title = this.getMyLanguage("seo","title.account.confirmEmailInvite");
            document.getElementsByTagName('meta')["description"].content = ''
            document.head.querySelector('meta[name=robots]').content = 'noindex, nofollow'
        },
    }
</script>

<style src="./custom-confirm-email.scss" lang="scss" scoped />