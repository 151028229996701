<template>
    <div class="success-modal">
        <div class="confirm-email-success">
            <h2 class="title-secondary">{{ getMyLanguage("confirm-email", "confirm-email.welcome") }}</h2>

            <div class="icon-confirm-email">
                <img src="~@/assets/images/icons/register-success.svg">
            </div>

            <div class="msg msg-success"><p>{{ getMyLanguage("confirm-email", "register-success") }}</p></div>
            <div class="msg msg-attention">
                <!-- <p v-html='getMyLanguage("confirm-email", "confirm-email-spam")'></p> -->
                <a :href="`/${flagSelected}/account/login`" class="btn-primary" v-html='getMyLanguage("confirm-email","cta-create-account")'></a>
            </div>
        </div>
    </div>
</template>

<script>
    import getMyContentLanguage from "@/services/contentLanguage.js";
  
    export default {
        data(){
            return{
                flagSelected: "en",
                PageEntity: "ecosystem",
            }
        },
        
        async mounted(){
            this.showContent = true
        },

        methods: {
            getMyLanguage(group, code){
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code)
            },

            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code

                document.title = this.getMyLanguage("seo","title.account.confirmEmail");
                document.getElementsByTagName('meta')["description"].content = ''
            },
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        },

        created () {
            document.title = this.getMyLanguage("seo","title.account.confirmEmail");
            document.getElementsByTagName('meta')["description"].content = ''
            document.head.querySelector('meta[name=robots]').content = 'noindex, nofollow'
        }
    }
</script>

<style src="./custom-confirm-email.scss" lang="scss" scoped />
<style scoped>
    .icon-confirm-email img{width: 140px !important; height: auto;}
    .btn-primary{
        height: 80px;
        line-height: 22px;
    }
</style>