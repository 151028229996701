<template>
    <router-view/>  
</template>

<script>
    import getMyContentLanguage from "@/services/contentLanguage.js";
    
    import apiPlatform from '@/config/apiPlatform.js'
    import apiInternational from '@/config/apiInternational.js'

    export default {
        data () {
            return {
                language: 'en',
                group: 'dne-platform',

                dataLanguages: [],
                dataCountries: [],

                dataPhaseCurrent: [],
                flagSelected: 'en',
                flagImg: '51021d86-49e9-4beb-804b-dfadf7a51300',
                statusLogin: {
                    logged: false
                },
                loginErrors: ""
            }
        },
        
        beforeMount(){
            this.loginStatus();
            this.flagUrl()
        },

        async mounted(){
            // LANGUAGES
            const resumeLanguages = await apiPlatform.get('api/v1/platform/language/dne_ecosystem')
            this.dataLanguages = resumeLanguages.data
            this.$store.commit('allLanguages',this.dataLanguages)

            this.flagImg = this.dataLanguages.filter(lang => lang.code === this.flagSelected)[0].flag
            this.$store.commit('preLanguageFlag',this.flagImg)

            // COUNTRIES
            const resumeCountries = await apiPlatform.get('api/v1/platform/country')
            this.dataCountries = resumeCountries.data
            this.$store.commit("allCountries",this.dataCountries)

            // const resumeLanguage = await apiPlatform.get('api/v1/platform/language/dne_ecosystem')
            // this.dataLanguages = resumeLanguage.data
            // this.$store.commit('allLanguages',this.dataLanguages)
            
            // this.flagImg = this.dataLanguages.filter(lang => lang.code === this.flagSelected)[0].flag
            // this.$store.commit('preLanguageFlag',this.flagImg)

            // PHASE CURRENT
            const resumePhaseCurrent = await apiInternational.get('/api/v2/ico/phase/current')
            this.dataPhaseCurrent = resumePhaseCurrent.data
            this.$store.commit('phaseCurrent',this.dataPhaseCurrent)

            this.checkUrlFlag()
        },

        methods: {
            loginStatus(){
                const tokenValue = localStorage.getItem('accessToken')

                if ( tokenValue != undefined || tokenValue != null ){
                    apiPlatform.get('/api/v1/platform/account/profile/')
                    .then(response => {
                        this.$store.commit('profile',response.data)
                        this.$store.commit('loginStatus', true)
                    })
                    .catch(error => {
                        let errorsLogin = JSON.stringify(error.response.status)
                        this.loginErrors = errorsLogin

                        if ( errorsLogin == 401 || errorsLogin == 403 ){
                            localStorage.removeItem('accessToken');
                        }
                    });
                }

                this.statusLogin.logged = false
                this.$store.commit('loginStatus', false)
            },

            flagUrl(){
                let code = String(window.location.pathname).split("/")[1];
                let flag = '51021d86-49e9-4beb-804b-dfadf7a51300'
                let language = "English"

                if ( !code ) {
                    code = 'en',
                    this.$store.commit('preLanguageFlag',flag)
                    localStorage.setItem('codeLanguage',code);
                    localStorage.setItem('flagLanguage','51021d86-49e9-4beb-804b-dfadf7a51300');
                } else{
                    this.flagSelected = code
                    this.$store.commit('preLanguage',code)
                    this.$store.commit('preLanguageName',language)
                }

                if (this.flagSelected === "ar"){
                    document.body.classList.add('ar');
                }
            },

            checkUrlFlag(){
                let code = String(window.location.pathname).split("/")[1];
                let codeLanguageLocalStore = localStorage.getItem('codeLanguage')

                if ( code != codeLanguageLocalStore ){
                    this.flagImg = this.dataLanguage.filter(lang => lang.code === this.flagSelected)[0].flag
                    this.$store.commit('preLanguageFlag',this.flagImg)
                }
            },

            changeLanguage(parLanguage){
                this.language = parLanguage;
                let url = String(window.location.pathname).split("/");
                
                url[1] = parLanguage;
                window.location.href = `${url.join('/')}`;
            },
            
            getMyLanguage(group, code){
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code)
            }
        }
    }
</script>

<style src="@/assets/scss/_custom-app.scss" lang="scss" />