<template>
   <div class="main">
      <div class="dne-entities-page">
         <div id="dne"></div>
         <Header :dataSource="PageEntity" />

         <section>
            <h1 class="title-secondary">DNE Exchange</h1>
            <div class="load load-200" v-if="!showContent"></div>
            
            <div class="container lp-private-sale" v-if="showContent">
               <div class="row">
                  <div class="col-12 col-lg-6">
                     <img src="~@/assets/images/content/dne-exchange.jpg" alt="DNE Exchange">
                  </div>

                  <div class="col-12 col-lg-6">
                     <div class="box-text">
                        <h2>
                           <small>{{ getMyLanguage("entities","title.entity") }}</small>
                           DNE <span>E</span>xchange
                        </h2>
                        
                        <p v-html='getMyLanguage("entities","text.dne-exchange")'></p>
                     </div>
                  </div>
               </div>
            </div>
         </section>

         <!-- <section class="content-construction">
            <div class="construction">
               <h1 class="title-primary">DNE <span>P</span>ay</h1>
               <p>{{ getMyLanguage("construction", "construction.title") }}</p>
            </div>
         </section> -->
      </div>
      
      <Footer />
      <CookieConsent />
   </div>
</template>


<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import CookieConsent from "@/components/CookieConsent.vue";
import getMyContentLanguage from "@/services/contentLanguage.js";

export default {
   components: {
      Header,
      Footer,
      CookieConsent
   },

   data(){
      return {
         PageEntity: "exchange",
         showContent: false
      }
   },

   async mounted(){
      setTimeout(() => {
         this.showContent = true
      }, 1000);
   },

   methods: {
      getMyLanguage(group, code) {
         return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code);
      }
   },

   created() {
      // document.title = this.getMyLanguage("seo", "title.dneExchange");
      document.title = "DNE Exchange | DNE Token";
      document.getElementsByTagName('meta')["description"].content = this.getMyLanguage("seo", "title.description.dneExchange");
   },
}
</script>

<style src="@/assets/scss/_entitiesPage.scss" lang="scss" scoped />
<style src="./custom-exchange.scss" lang="scss" scoped />

<style>
   html,body,#app,.main{height: 100%;}
</style>