<template>
    <div class="main">
        <div class="not-found">
            <div id="dne"></div>
            <Header :dataSource="PageEntity" />

            <section class="erro404">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <h1 class="title-secondary">{{ this.getMyLanguage("error", "error.404") }}</h1>
                        </div>
                        <div class="col-12 img-404">
                            <img src="~@/assets/images/icons/404.svg" alt="404">
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <Footer />
        <CookieConsent />
    </div>
</template>


<script>
    import Header from '@/components/Header.vue';
    import Footer from '@/components/Footer.vue';
    import CookieConsent from '@/components/CookieConsent.vue';
    import getMyContentLanguage from "@/services/contentLanguage.js";

    export default {
        components: {
            Header,
            Footer,
            CookieConsent
        },

        data (){
            return {
                PageEntity: "ecosystem",
            }
        },
        
        methods: {
            getMyLanguage(group, code){
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code)
            },
        },

        created () {
            document.title = this.getMyLanguage("seo","title.404");
            document.getElementsByTagName('meta')["description"].content = ''
            document.head.querySelector('meta[name=robots]').content = 'noindex, nofollow'
        },
    }
</script>

<style src="./custom-404.scss" lang="scss" scoped />